import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

function toastArgs() {
  const args = {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 6000,
    hideProgressBar: true,
  };
  return args;
}

export const toastSuccessDev = (message) => {
  if (window.$NODE_ENV === 'development') {
    toast.success(message, toastArgs());
  }
};

export const toastSuccess = (message) => {
  toast.success(message, toastArgs());
};

export const toastError = (message) => {
  toast.error(message, toastArgs());
};

export const manageCatch = (dispatch, res, failure) => {
  const err = { ...res }.response;
  if (err) {
    if (err.status === 401) {
      toastError(err.statusText);
      dispatch(push('/logout'));
    } else {
      console.log(err);
      toastError(`${err.statusText}. ${_get(err, 'data.errors.message', 'Something went wrong')}`);
      dispatch(failure());
      return _omit(_get(err, 'data.errors', {}), ['message']);
    }
  } else {
    console.error(res);
  }
  return undefined;
};
