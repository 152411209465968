import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';

const ReporterNames = ['Centrix', 'Illion', 'Equifax'];

const BatchStatuses = {
  UNPROCESSED: {
    description: 'Unprocessed',
    processable: true,
    publishable: false,
    mutable: true,
  },
  PROCESSING: {
    description: 'Processing',
    processable: false,
    publishable: false,
    mutable: false,
  },
  PROCESS_ERROR: {
    description: 'Process Error (admin notified)',
    processable: false,
    publishable: false,
    mutable: true,
  },
  PROCESSED: {
    description: 'Processed (awaiting publishing)',
    processable: true,
    publishable: true,
    mutable: true,
  },
  PUBLISHING: {
    description: 'Publishing',
    processable: false,
    publishable: false,
    mutable: false,
  },
  PUBLISH_ERROR: {
    description: 'Publish Error (admin notified)',
    processable: false,
    publishable: false,
    mutable: false,
  },
  PUBLISHED: {
    description: 'Published (awaiting responses)',
    processable: false,
    publishable: false,
    mutable: false,
  },
  POLLING: {
    description: 'Polling for responses',
    processable: false,
    publishable: false,
    mutable: false,
  },
  POLL_ERROR: {
    description: 'Response error (admin notified)',
    processable: false,
    publishable: false,
    mutable: false,
  },
  COMPLETED: {
    description: 'Completed',
    processable: false,
    publishable: false,
    mutable: false,
  },
};

const MutableBatchStatuses = _keys(_pickBy(BatchStatuses, (bs) => bs.mutable));
const ProcessableBatchStatuses = _keys(_pickBy(BatchStatuses, (bs) => bs.processable));
const PublishableBatchStatuses = _keys(_pickBy(BatchStatuses, (bs) => bs.publishable));

const AccountRelationships = {
  1: 'Individual',
  2: 'Joint',
  3: 'Guarantor',
};

const AccountStatuses = {
  A: { updateable: true, endState: false, description: 'Description' },
  C: { updateable: false, endState: true, description: 'Active Closed' },
  D: {
    updateable: false,
    endState: true,
    description: 'Outsourced to Debt Collector, account closed with Credit Provider',
  },
  U: {
    updateable: true,
    endState: false,
    description: 'Outsourced to Debt Collector but account still open with Credit Provider',
  },
  H: { updateable: true, endState: false, description: 'Hardship' },
  G: { updateable: true, endState: false, description: 'Scheme of Arrangement Inactive' },
  I: { updateable: true, endState: false, description: 'Inactive' },
  N: {
    updateable: false,
    endState: true,
    description:
      'Closed involuntarily – no further credit available and no remaining outstanding balance. Credit is terminated or otherwise ceases to be in force',
  },
  S: { updateable: true, endState: false, description: 'Suspended' },
  V: { updateable: true, endState: false, description: 'Credit non-compliance action ' },
  W: { updateable: true, endState: false, description: 'Confirmed Credit non-compliance' },
  X: { updateable: false, endState: true, description: 'Debt sold' },
  Z: { updateable: true, endState: false, description: 'Serious Credit Infringement' },
};

const AccountTransferReasons = {
  M: 'Credit Provider has merged / been taken over',
  R: 'Assignment Reversal T Assignment Transfer',
  X: 'Account sold',
};

const AccountTypes = {
  AL: { creditLimit: true, description: 'Auto Loan' },
  CC: { creditLimit: true, description: 'Credit Card' },
  CA: { creditLimit: false, description: 'Charge Card' },
  OD: { creditLimit: true, description: 'Overdraft' },
  PL: { creditLimit: true, description: 'Personal Loan' },
  R: { creditLimit: true, description: 'Equipment Hire or Rental' },
  RM: { creditLimit: true, description: 'Mortgage' },
  TC: { creditLimit: false, description: 'Telecommunications Services' },
  UA: { creditLimit: false, description: 'Utilities (electric / gas provider)' },
};

const AddressTypes = {
  C: 'Current or last known address P Previous Address',
  M: 'Mailing Address',
};

const BatchTypes = {
  I: 'Initial Load',
  P: 'Periodic Load',
  T: 'Transform to Comprehensive',
  C: 'Corrections',
};

const CreditPurposes = {
  R: 'Consumer',
};

const CreditTypes = {
  F: 'Fixed',
  R: 'Revolving',
  U: 'Unspecified',
};

const DefaultStatuses = {
  O: 'Means that the payment is still outstanding.',
  C:
    'Current means that the account has been brought up to date and that the account is still open.',
  P: 'Paid means that the whole account has been paid in full and that the account is now closed.',
  S: 'Settled means that partial payment of the debt has been accepted by the creditor.',
};

const Genders = {
  M: 'Male',
  F: 'Female',
  U: 'Unknown/unspecified',
};

const IndustryTypes = {
  F: 'Finance Services',
  I: 'Insurance company',
  T: 'Telco',
  U: 'Utility',
  B: 'Bank',
};

const Modes = {
  P: 'Production data load',
  T: 'Test data load',
};

const PaymentFrequencies = {
  M: 'Monthly',
  F: 'Fortnightly',
  H: 'Half-yearly',
  Q: 'Quarterly',
  W: 'Weekly',
  Y: 'Yearly',
  N: 'No Repayment required T 30 day cycle (Telco’s)',
  V: 'Variable Repayment plan',
};

const PaymentStatuses = {
  N: { category: 'Current ', description: 'No payment requried.' },
  0: {
    category: 'Current',
    description: 'Payments up-to-date as at the cycle date. Overdraft with limited agreed.',
  },
  1: {
    category: '1-29 days past due',
    description:
      'Payments 1-29 days in arrears as at the cycle date. A grace period of up to 14 calendar days may be observed by individual credit provider.',
  },
  2: {
    category: '30-59 days past due',
    description: 'Payments 30-59 days in arrears as at the cycle date',
  },
  3: {
    category: '60-89 days past due',
    description: 'Payments 60-89 days in arrears as at the cycle date',
  },
  4: {
    category: '90-119 days past due',
    description: 'Payments 90-119 days in arrears as at the cycle date',
  },
  5: {
    category: '120-149 days past due',
    description: 'Payments 120-149 days in arrears as at the cycle date',
  },
  6: {
    category: '150-179 days past due',
    description: 'Payments 150-179 days in arrears as at the cycle date',
  },
  X: {
    category: '180+ days past due date',
    description: 'Payments more than 180 days in arrears as at the cycle date',
  },
  H: { category: 'Hardship ', description: 'Customer in Hardship' },
  R: { category: 'Re-aged', description: 'An account has been re-aged by credit provider' },
};

const PaymentTypes = {
  P: 'Principal and Interest',
  I: 'Interest only',
  L: 'Buy now – pay later',
  F: 'Interest free instalments X Other',
};

const SecuredCredits = {
  S: 'Secured',
  U: 'Unsecured',
};

const SignatoryRoles = {
  N: 'New Account Owner',
  P: 'Previous Account Owner',
};

const YesNoes = {
  N: 'No',
  Y: 'Yes',
};

export {
  ReporterNames,
  BatchStatuses,
  MutableBatchStatuses,
  ProcessableBatchStatuses,
  PublishableBatchStatuses,
  IndustryTypes,
  AccountRelationships,
  AccountStatuses,
  AccountTransferReasons,
  AccountTypes,
  AddressTypes,
  BatchTypes,
  CreditPurposes,
  CreditTypes,
  DefaultStatuses,
  Genders,
  Modes,
  PaymentFrequencies,
  PaymentStatuses,
  PaymentTypes,
  SecuredCredits,
  SignatoryRoles,
  YesNoes,
};
