import React from 'react';
import { Route, Switch } from 'react-router-dom';

import _clone from 'lodash/clone';

import AuthLayout from '../layouts/auth_layout';
import DashboardLayout from '../layouts/dashboard_layout';

import AuthRoutes from './auth_routes';
import DashboardRoutes from './dashboard_routes';

import Page404 from '../containers/page_404';

import {
  userIsAuthenticated,
  userIsNotAuthenticated,
} from '../lib/auth';

const renderRoute = (
  Layout,
  {
    path,
    component: Component,
    requireAuthenticated,
    requireNotAuthenticated,
  },
) => {
  let RenderComponent = Component;
  if (requireAuthenticated) {
    RenderComponent = userIsAuthenticated(RenderComponent);
  } else if (requireNotAuthenticated) {
    RenderComponent = userIsNotAuthenticated(RenderComponent);
  }
  return (
    <Route
      key={path}
      path={path}
      exact
      render={props => (
        <Layout>
          <RenderComponent {...props} />
        </Layout>
      )}
    />
  );
};

const renderRoutes = (layout, routes) => {
  if (routes.children) {
    return routes.children.map(child => (
      renderRoute(layout, child)
    ));
  } else {
    return renderRoute(layout, routes);
  }
};

const Routes = () => (
  <Switch>
    { renderRoutes(AuthLayout, AuthRoutes) }
    { renderRoutes(DashboardLayout, DashboardRoutes) }
    <Route
      render={() => (
        <AuthLayout>
          <Page404 />
        </AuthLayout>
      )}
    />
  </Switch>
);

export default Routes;
