import React from 'react';

const renderError = (error) => {
  if (error) {
    return (
      <>
        <p>Something went wrong</p>
        {process.env.NODE_ENV !== 'production' && (
          <pre>{JSON.stringify(error, undefined, 2)}</pre>
        )}
      </>
    );
  }
  return undefined;
};

export default renderError;
