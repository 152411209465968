import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const BatchShowJson = ({ json, header }) => (
  <Row>
    <Col>
      <Card>
        {header && <Card.Header>{header}</Card.Header>}
        <Card.Body style={{ height: '400px', overflowY: 'scroll' }}>
          <pre>{JSON.stringify(json, undefined, 2)}</pre>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

export default BatchShowJson;
