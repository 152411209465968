import React from 'react';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery, useMutation } from 'react-apollo';
import { useSelector, useDispatch } from 'react-redux';

import { mutationSuccess, mutationFailure } from '../actions/mutation_actions';
import { renderOverlay, renderError, renderPage } from '../components';
import Confirm from '../components/confirm';
import userDeleteMutation from '../mutations/user_delete_mutation';
import userListQuery from '../queries/user_list_query';

const UserList = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [userDelete] = useMutation(userDeleteMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
  } = useQuery(userListQuery);

  const pageRefetchClicked = () => pageRefetch();

  const userDeleteClicked = (e) => {
    const userId = parseInt(e.currentTarget.getAttribute('data-id'), 10);
    console.log(userId);
    return userDelete({
      variables: { id: userId },
      refetchQueries: () => [{ query: userListQuery }],
    })
      .then(() => {
        dispatch(mutationSuccess('User delete'));
      })
      .catch((err) => dispatch(mutationFailure(err)));
  };

  const isSelf = (userId) => user.id === userId;

  const renderBooleanCell = (bool) => (bool ? 'Yes' : 'No');

  const renderActionsCell = (cell, row) => (
    <>
      <LinkContainer exact to={`/users/${row.id}/edit`}>
        <Button variant="outline-primary" size="small" block>
          edit
        </Button>
      </LinkContainer>
      <br />

      {user.admin && (
        <Confirm
          comfirmId={`user-delete=${row.id}`}
          dataId={row.id}
          onConfirm={userDeleteClicked}
          title="Delete User"
          body="This will DELETE the user."
          confirmText="Confirm"
        >
          <Button variant="outline-danger" size="small" disabled={isSelf(row.id)} block>
            delete
          </Button>
        </Confirm>
      )}
    </>
  );

  const tableColumns = [
    {
      dataField: 'email',
      text: 'Email',
      style: { verticalAlign: 'top' },
    },
    // {
    //   dataField: 'fullName',
    //   text: 'Name',
    //   style: { verticalAlign: 'top' },
    // },
    {
      dataField: 'admin',
      text: 'Admin?',
      style: { verticalAlign: 'top' },
      formatter: renderBooleanCell,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      formatter: renderActionsCell,
      style: { verticalAlign: 'top' },
    },
  ];

  const renderContent = () => (
    <Container fluid className="p-0">
      <Row>
        <Col sm={12}>
          <div className="float-none">
            <div className="float-left">
              <h1 className="h3 mb-3">Users</h1>
            </div>
            <div className="float-right">
              <Button className="mr-2" variant="primary" onClick={pageRefetchClicked}>
                Refresh
              </Button>
              <LinkContainer exact to="/users/new">
                <Button variant="primary">New User</Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                data={pageData.userList}
                columns={tableColumns}
                bootstrap4
                bordered={false}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return (
    <div>
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {renderPage(pageLoading, pageError) && renderContent()}
    </div>
  );
};

export default UserList;
