import React from 'react';
import { Line } from 'react-chartjs-2';

import {
  Badge,
  Card,
} from 'react-bootstrap';

const LineChart = () => {
  const theme = {
    primary: '#2c7be5',
    secondary: '#9d7bd8',
    tertiary: '#5997eb',
    success: '#4caf50',
    info: '#47bac1',
    warning: '#ff9800',
    danger: '#e51c23',
  };

  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Accounts Imported',
        fill: true,
        backgroundColor: 'transparent',
        borderColor: theme.primary,
        data: [
          2015,
          1465,
          1487,
          1796,
          1387,
          2123,
          2866,
          2548,
          3902,
          4938,
          3917,
          4927,
        ],
      },
      {
        label: 'Import Failures',
        fill: true,
        backgroundColor: 'transparent',
        borderColor: theme.warning,
        borderDash: [4, 4],
        data: [
          928,
          734,
          626,
          893,
          921,
          1202,
          1396,
          1232,
          1524,
          2102,
          1506,
          1887,
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: 'rgba(0,0,0,0.05)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 500,
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: 'rgba(0,0,0,0)',
            fontColor: '#fff',
          },
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Badge variant="primary" className="float-right">
          Monthly
        </Badge>
        <Card.Title tag="h5" className="mb-0">
          Total Accounts
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="chart chart-lg">
          <Line data={data} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default LineChart;
