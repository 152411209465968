/* eslint-disable no-nested-ternary */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import _get from 'lodash/get';

const BatchShowBatchDetail = ({ batch, BatchStatuses, BatchTypes, IndustryTypes, Modes }) => {
  const {
    id,
    providerReference,
    batchStatus,
    batchType,
    industryType,
    mode,
    version,
    nameOfTheProvider,
    notificationEmail,
    mainContactEmail,
    mainContactName,
    mainContactPhone,
    optionalContactEmail,
    optionalContactName,
    optionalContactPhone,
    extractAt,
    fileFilename,
  } = batch;
  const dataRow = (dt, dd = null, dtW = 4, ddW = 8) => (
    <>
      <Col as="dt" sm={dtW}>
        {dt}
      </Col>
      <Col as="dd" sm={ddW}>
        {dd ? (
          Array.isArray(dd) ? (
            dd.map((d, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={`${dt}${index}`}>
                {d}
                <br />
              </span>
            ))
          ) : (
            dd
          )
        ) : (
          <span className="text-muted">-</span>
        )}
      </Col>
    </>
  );
  return (
    <Row>
      <Col>
        <Card id="batchShow">
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Row as="dl">{dataRow('ID', id)}</Row>
                <Row as="dl">{dataRow('Provider Reference', providerReference)}</Row>
                <Row as="dl">
                  {dataRow('Batch Status', _get(BatchStatuses, [batchStatus, 'description']))}
                </Row>
                <Row as="dl">{dataRow('Batch Type', BatchTypes[batchType])}</Row>
                <Row as="dl">{dataRow('Industry Type', IndustryTypes[industryType])}</Row>
                <Row as="dl">{dataRow('Mode', Modes[mode])}</Row>
                <Row as="dl">{dataRow('Version', version)}</Row>
                <Row as="dl">{dataRow('Name of the Provider', nameOfTheProvider)}</Row>
                <Row as="dl">{dataRow('Extract At', extractAt)}</Row>
                <Row as="dl">{dataRow('File Path Name', fileFilename)}</Row>
              </Col>
              <Col sm={6}>
                <Row as="dl">{dataRow('Notification Email', notificationEmail)}</Row>
                <Row as="dl">{dataRow('Main Contact Email', mainContactEmail)}</Row>
                <Row as="dl">{dataRow('Main Contact Name', mainContactName)}</Row>
                <Row as="dl">{dataRow('Main Contact Phone', mainContactPhone)}</Row>
                <Row as="dl">{dataRow('Optional Contact Email', optionalContactEmail)}</Row>
                <Row as="dl">{dataRow('Optional Contact Name', optionalContactName)}</Row>
                <Row as="dl">{dataRow('Optional Contact Phone', optionalContactPhone)}</Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default BatchShowBatchDetail;
