/* eslint-disable import/prefer-default-export */
import { getMainDefinition } from 'apollo-utilities';

import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import _omit from 'lodash/omit';

import batchListQuery from '../queries/batch_list_query';
import batchQuery from '../queries/batch_query';
import batchTemplateListQuery from '../queries/batch_template_list_query';
import batchTemplateQuery from '../queries/batch_template_query';
import reporterListQuery from '../queries/reporter_list_query';
import reporterQuery from '../queries/reporter_query';
import userListQuery from '../queries/user_list_query';
import userQuery from '../queries/user_query';

const getQueries = (recordType) => {
  switch (recordType) {
    case 'reporter':
      return [reporterQuery, reporterListQuery];
    case 'batch':
      return [batchQuery, batchListQuery];
    case 'batchTemplate':
      return [batchTemplateQuery, batchTemplateListQuery];
    case 'user':
      return [userQuery, userListQuery];
    default:
      return [undefined, undefined];
  }
};

const processQueries = ({
  recordId,
  recordType,
  mutationType,
  argGetQuery,
  argListQuery,
  listVariables = [{}],
  forceFetch = false,
  client,
}) => {
  const [defaultGetQuery, defaultListQuery] = getQueries(recordType);
  const getQuery = argGetQuery || defaultGetQuery;
  const listQuery = argListQuery || defaultListQuery;
  let recordQuery;
  if (getQuery && mutationType !== 'DELETE') {
    recordQuery = client.query({
      query: getQuery,
      variables: { id: recordId },
      fetchPolicy: 'network-only',
    });
  } else {
    recordQuery = Promise.resolve({});
  }
  recordQuery.then(() => {
    let collectionQuery = Promise.resolve([]);
    if (listQuery) {
      const {
        name: { value: listQueryName },
      } = getMainDefinition(listQuery);
      if (forceFetch || mutationType !== 'UPDATE') {
        const collectionPromises = listVariables.map((listVariable) => {
          const queryVariables = {
            query: listQuery,
            variables: _omitBy(listVariable, _isNil),
            fetchPolicy: 'network-only',
          };
          let collection;
          try {
            collection = client.readQuery(_omit(queryVariables, ['fetchPolicy']));
          } catch {
            collection = undefined;
          }
          if (collection) {
            if (forceFetch || mutationType === 'CREATE') {
              return client.query(queryVariables);
            } else if (mutationType === 'DELETE') {
              const newCollection = [...collection[listQueryName]];
              const index = newCollection.findIndex((record) => record.id === recordId);
              if (index > -1) {
                return client.query(queryVariables);
              } else {
                return Promise.resolve([]);
              }
            }
          }
          return Promise.resolve([]);
        });
        collectionQuery = Promise.all(collectionPromises);
      }
    }
    collectionQuery.then(() => {
      if (window.$NODE_ENV === 'development') {
        console.log(`${mutationType} ${recordType} success: `, listVariables);
      }
    });
  });
};

const processMutationSubscription = (mutation, client) => {
  if (window.$NODE_ENV === 'development') {
    console.log(mutation);
  }
  const {
    data: {
      mutation: { recordId, recordType, mutationType },
    },
  } = mutation;
  processQueries({
    recordId,
    recordType,
    mutationType,
    client,
  });
};

export { processMutationSubscription };
