import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

const Footer = () => (
  <footer
    className="footer"
    // style={{
    //   padding: 0,
    //   position: 'absolute',
    //   bottom: 0,
    //   right: 0,
    //   width: '100%',
    //   height: '60px',
    //   lineHeight: '60px',
    // }}
  >
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-left">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                Support
              </span>
            </li>
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                Help Center
              </span>
            </li>
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                Privacy
              </span>
            </li>
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                Terms of Service
              </span>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-right">
          <p className="mb-0">
            &copy;
            {new Date().getFullYear()}
            {' - '}
            <span href="/" className="text-muted">
              Happy Prime Limited
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
