/* eslint-disable import/prefer-default-export */
import mime from 'mime-types';

import _cloneDeepWith from 'lodash/cloneDeepWith';
import _defaultTo from 'lodash/defaultTo';
import _includes from 'lodash/includes';
import _last from 'lodash/last';
import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import _pick from 'lodash/pick';

import Api from './api';

const omitByNil = (data) => {
  return _omitBy(data, _isNil);
}

export function pickValues(data, whitelist = ['id']) {
  return _pick(omitByNil(data), whitelist);
}

const integers = [
  'someInteger',
];

const floats = [
  'someFloat',
];

function inputTyper(value, key) {
  if (key && !(typeof value === 'object')) {
    if (_includes(floats, key)) {
      return _defaultTo(parseFloat(value), null);
    } else if (_includes(integers, key)) {
      return _defaultTo(parseInt(value, 10), null);
    } else if (value === '') {
      return null;
    }
  }
  return undefined;
}

export function coerceInput(data) {
  const coercedData = _cloneDeepWith(data, inputTyper);
  return coercedData;
}


export function getExport(
  exportPath,
  displayName,
  args = {},
) {
  const exportType = _last(exportPath.split('.'));
  const contentType = mime.lookup(exportType);
  return Api.get(
    `/export/${exportPath}`,
    args,
    { responseType: 'arraybuffer' },
  )
    .then((resp) => {
      const blob = new Blob([resp.data], { type: contentType });
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = displayName || exportPath;
      link.click();
    });
}
