import React from 'react';
import _get from 'lodash/get';
import Loader from './loader';

const renderHocOverlay = (props, queryName = 'pageQuery') => {
  if (props.settingsMutating || _get(props, [queryName, 'loading'])) {
    return <Loader />;
  }
  return undefined;
};

export default renderHocOverlay;
