import React, { Component } from 'react';
import {
  Row,
  Form,
  Col,
} from 'react-bootstrap';

import DateTime from 'react-datetime';
import moment from 'moment';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { isInvalid, validationText } from './helpers';

moment.locale('en-nz');

class ReactDateTimeField extends Component {
  constructor(props) {
    super(props);
    this.fieldName = props.input.name;
    this.fieldNameError = `${props.input.name}_error`;
    this.fieldFormat = [props.dateFormat, props.timeFormat].filter(format => format).join(' ');
    this.state = {
      [this.fieldName]: props.input.value ? moment(props.input.value).format(this.fieldFormat) : '',
      [this.fieldNameError]: {},
    };
    this.handleInputFieldChange = this.handleInputFieldChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const prevValue = _get(prevProps, 'input.value');
    const value = _get(this.props, 'input.value');
    if (prevValue !== value) {
      const newState = {
        [this.fieldName]: value ? moment(value).format(this.fieldFormat) : '',
        [this.fieldNameError]: {},
      };
      if (!_isEqual(this.state, newState)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(newState);
      }
    }
  }

  handleInputFieldChange(date) {
    if (moment.isMoment(date)) {
      const args = {
        [this.fieldName]: date.format(this.fieldFormat),
        [this.fieldNameError]: {},
      };
      this.setState(args);
    } else {
      const args = {
        [this.fieldNameError]: { touched: true, invalid: true, error: this.props.helpText },
      };
      this.setState(args);
    }
    if (this.props.handleDateTimeChange) {
      this.props.handleDateTimeChange(date, this.props.input.name);
    }
  }

  renderInput() {
    const {
      inputStyle,
      dateFormat,
      timeFormat,
      closeOnSelect,
    } = this.props;

    const invalid = isInvalid(this.state[this.fieldNameError]);

    return (
      <DateTime
        value={this.state[this.fieldName]}
        {... (inputStyle && { style: inputStyle })}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        onChange={this.handleInputFieldChange}
        closeOnSelect={closeOnSelect}
        {...(invalid ? { inputProps: { className: 'form-control is-invalid' } } : { inputProps: { className: 'form-control' } })}
      />
    );
  }

  render() {
    const {
      input,
      groupStyle,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
      groupClassName,
    } = this.props;

    const invalid = isInvalid(this.state[this.fieldNameError]);

    const innerContent = (
      <React.Fragment>
        {
          labelWidth === 0
            && this.props.children
            && <Form.Label>{this.props.children}</Form.Label>
        }
        {this.renderInput()}
        {
          helpBlock && invalid && (
            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
              {validationText(this.state[this.fieldNameError], helpText)}
            </Form.Control.Feedback>
          )
        }
        {
          helpBlock && helpText && !invalid && (
            <div style={{ width: '100%', marginTop: '0.25rem', fontSize: '80%' }}>
              {helpText}
            </div>
          )
        }
      </React.Fragment>
    );

    return (
      <Form.Group
        controlId={input.name}
        {... (groupStyle && { style: groupStyle })}
        {... (groupClassName && { className: groupClassName })}
        {... (labelWidth > 0 && { as: Row })}
        {... (labelWidth === 0 && { as: Col })}
      >
        {
          labelWidth > 0 ? (
            <React.Fragment>
              <Form.Label column sm={labelWidth}>{this.props.children}</Form.Label>
              <Col sm={inputWidth}>
                { innerContent }
              </Col>
            </React.Fragment>
          ) : (
            innerContent
          )
        }
      </Form.Group>
    );
  }
}

ReactDateTimeField.defaultProps = {
  labelWidth: 0,
  inputWidth: 12,
  helpText: '',
  helpBlock: true,
  dateFormat: false,
  timeFormat: false,
  closeOnSelect: false,
};

export default ReactDateTimeField;
