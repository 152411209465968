import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  InputGroup,
} from 'react-bootstrap';
import BlurOnlyFormControl from './blur_only_form_control';
import { isInvalid, validationText } from './helpers';

class InputField extends Component {
  renderFormControl() {
    const {
      meta,
      blurOnly,
      noTab,
      placeholder,
      type,
      input,
      inputStyle,
      asElement,
      defaultSelectOption,
      selectOptions,
      rows,
      size,
      plainText,
      customOnChange,
      customOnBlur,
    } = this.props;

    const invalid = isInvalid(meta);
    const onBlur = customOnBlur || input.onBlur;
    const onChange = customOnChange || input.onChange;

    const commonProps = {
      type,
      value: input.value,
      ...(placeholder && { placeholder }),
      ...(asElement && { as: asElement }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
      ...(invalid && { isInvalid: true }),
      ...(size && { size }),
    };
    if (plainText) {
      return (
        <Form.Control plaintext readOnly value={input.value} />
      );
    } else if (selectOptions) {
      return (
        <Form.Control
          {...commonProps}
          onChange={onChange}
        >
          {
            defaultSelectOption ? <option key={0} value="">{ }</option> : ''
          }
          {
            selectOptions
              .map(option => <option key={option.id} value={option.id}>{option.name}</option>)
          }

        </Form.Control>
      );
    } else if (blurOnly) {
      return (
        <BlurOnlyFormControl
          {...commonProps}
          onBlur={onBlur}
        />
      );
    } else {
      return (
        <Form.Control
          {...commonProps}
          onBlur={onBlur}
          onChange={onChange}
        />
      );
    }
  }

  render() {
    const {
      input,
      meta,
      groupStyle,
      groupClassName,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
      prepend,
      append,
    } = this.props;

    const invalid = isInvalid(meta);

    const innerContent = (
      <React.Fragment>
        {
          labelWidth === 0
            && this.props.children
            && <Form.Label>{this.props.children}</Form.Label>
        }
        {
          prepend || append ? (
            <InputGroup>
              {
                prepend && (
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      {prepend}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                )
              }
              {this.renderFormControl()}
              {
                append && (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      {append}
                    </InputGroup.Text>
                  </InputGroup.Append>
                )
              }
            </InputGroup>
          ) : (
            this.renderFormControl()
          )
        }
        {
          helpBlock && invalid && (
            <Form.Control.Feedback type="invalid">
              {validationText(meta, helpText)}
            </Form.Control.Feedback>
          )
        }
        {
          helpBlock && helpText && !invalid && (
            <div style={{ width: '100%', marginTop: '0.25rem', fontSize: '80%' }}>
              {helpText}
            </div>
          )
        }

      </React.Fragment>
    );

    return (
      <Form.Group
        controlId={input.name}
        {... (groupStyle && { style: groupStyle })}
        {... (groupClassName && { className: groupClassName })}
        {... (labelWidth > 0 && { as: Row })}
        {... (labelWidth === 0 && { as: Col })}
      >
        {
          labelWidth > 0 ? (
            <React.Fragment>
              <Form.Label column sm={labelWidth}>{this.props.children}</Form.Label>
              <Col sm={inputWidth}>
                { innerContent }
              </Col>
            </React.Fragment>
          ) : (
            innerContent
          )
        }
      </Form.Group>
    );
  }
}

InputField.defaultProps = {
  labelWidth: 0,
  inputWidth: 12,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  plainText: false,
};

export default InputField;
