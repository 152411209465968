import { push } from 'connected-react-router';
import _get from 'lodash/get';
import actionTypes from '../constants';
import {
  toastSuccess,
  toastError,
  // manageFormErrors, // todo process errors returned from server
} from '../lib/action_helpers';

export function mutationSet(set) {
  return {
    type: actionTypes.SETTINGS_SET,
    payload: {
      mutating: set,
    },
  };
}

export function mutationSuccess(message, redirect) {
  return function mutationSuccessDispatch(dispatch) {
    dispatch(mutationSet(false));
    if (message) {
      toastSuccess(`${message} ok`);
    }
    if (redirect) {
      dispatch(push(redirect));
    }
  };
}

export function mutationFailure(
  err,
  // reduxForm = false,
  // compactNestedObjectErrorArrays = false,
) {
  return function mutationFailureDispatch(dispatch) {
    dispatch(mutationSet(false));
    const apiMessage = _get(err, ['graphQLErrors', '0', 'data', 'apiMessage']);
    const message = _get(err, ['graphQLErrors', '0', 'message']);
    if (window.$NODE_ENV === 'development') {
      if (!(message || apiMessage)) {
        console.log('[MutationFailure error]', JSON.stringify(err, undefined, 2));
      }
    }
    toastError(apiMessage || message || 'Something went wrong');
    // todo  manage server errors for redux form
    // if (reduxForm) {
    //   const apiErrors = _get(err, ['graphQLErrors', '0', 'data', 'apiErrors'], {});
    //   if (Object.keys(apiErrors).length > 0) {
    //     return manageFormErrors(apiErrors, compactNestedObjectErrorArrays);
    //   } else {
    //     return new Promise((resolve) => {
    //       setTimeout(() => {
    //         resolve();
    //       }, 1);
    //     });
    //   }
    // } else {
    //   return undefined;
    // }
  };
}
