/* eslint-disable no-useless-escape */
import { BatchTypes, IndustryTypes, Modes } from './tables';

const batchTemplateSchema = {
  type: 'object',
  required: ['industryType', 'version', 'mode', 'batchType', 'nameOfTheProvider'],
  properties: {
    description: {
      type: 'string',
      minLength: 10,
      maxLength: 255,
    },
    providerReference: {
      type: 'string',
      minLength: 1,
      maxLength: 20,
    },
    industryType: {
      type: 'string',
      enum: Object.keys(IndustryTypes),
    },
    notificationEmail: {
      type: 'string',
      minLength: 7,
      maxLength: 60,
      format: 'email',
    },
    version: {
      type: 'string',
      const: '3.01',
    },
    mode: {
      type: 'string',
      enum: Object.keys(Modes),
    },
    batchType: {
      type: 'string',
      enum: Object.keys(BatchTypes),
    },
    nameOfTheProvider: {
      type: 'string',
      minLength: 7,
      maxLength: 60,
    },
    mainContactName: {
      type: 'string',
      minLength: 2,
      maxLength: 40,
    },
    mainContactEmail: {
      type: 'string',
      minLength: 7,
      maxLength: 60,
      format: 'email',
    },
    mainContactPhone: {
      type: 'string',
      minLength: 8,
      maxLength: 12,
      pattern: '^(\\(|\\)|\\+|\\s|\\d)+$',
    },
    optionalContactName: {
      type: 'string',
      minLength: 2,
      maxLength: 40,
    },
    optionalContactEmail: {
      type: 'string',
      minLength: 7,
      maxLength: 60,
      format: 'email',
    },
    optionalContactPhone: {
      type: 'string',
      minLength: 8,
      maxLength: 12,
      pattern: '^(\\(|\\)|\\+|\\s|\\d)+$',
    },
  },
};

// Form validation is identical to batch validation
const batchTemplateFormSchema = batchTemplateSchema;

export { batchTemplateSchema, batchTemplateFormSchema };
