/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Card, Col, Row, Button, Modal, Tabs, Tab } from 'react-bootstrap';

import _get from 'lodash/get';

import BatchShowJson from './json';

const BatchShowBatchResponse = ({ batchResponse, reporter, accounts }) => {
  const [modalToggle, setModalToggle] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});

  const handleSetModalToggle = () => {
    if (modalToggle) {
      setSelectedAccount({});
    }
    setModalToggle(!modalToggle);
  };

  const accountResponseDetailClicked = (e) => {
    e.preventDefault();
    const accountId = parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const account = accounts.find((ac) => ac.id === accountId);
    setSelectedAccount(account);
    handleSetModalToggle();
  };

  const batchResponseDetailClicked = (e) => {
    e.preventDefault();
    handleSetModalToggle();
  };

  const dataRow = (dt, dd = null, dtW = 2, ddW = 10) => (
    <>
      <Col as="dt" sm={dtW}>
        {/* {`${_last(dt.split('.'))}:`} */}
        {`${dt.replace(/\/?CRAReportBatch\/Accounts\/Account\//, '')}`}
      </Col>
      <Col as="dd" sm={ddW}>
        {dd ? (
          Array.isArray(dd) ? (
            dd.map((d, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={`${dt}${index}`}>
                {d}
                <br />
              </span>
            ))
          ) : (
            dd
          )
        ) : (
          <span className="text-muted">-</span>
        )}
      </Col>
    </>
  );

  return (
    <>
      <Modal show={modalToggle} onHide={handleSetModalToggle} size="lg" centered>
        <Modal.Body>
          {selectedAccount.id ? (
            <BatchShowJson json={selectedAccount.accountMessagesResponse} />
          ) : (
            <Tabs defaultActiveKey="batchHeaderResponse">
              <Tab eventKey="batchHeaderResponse" title="Batch Header Response">
                <BatchShowJson json={batchResponse.batchHeaderResponse} />
              </Tab>
              <Tab eventKey="batchStatisticsResponse" title="Batch Statistics Response">
                <BatchShowJson json={batchResponse.batchStatisticsResponse} />
              </Tab>
            </Tabs>
          )}
        </Modal.Body>
      </Modal>
      <Row>
        <Col>
          <Card className="accountError">
            <Card.Header>
              <div className="float-none">
                <div className="float-left">
                  <h1 className="h4 mt-2">{`Batch Reponse for ${reporter.reporterName}`}</h1>
                </div>
                <div className="float-right">
                  <Button
                    variant="link"
                    onClick={batchResponseDetailClicked}
                    disabled={modalToggle || !batchResponse.published || !batchResponse.responded}
                  >
                    detail..
                  </Button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row as="dl">
                <Col as="dt" sm={2}>
                  Published?
                </Col>
                <Col as="dd" sm={10}>
                  {batchResponse.published ? 'Yes' : 'No'}
                </Col>
              </Row>
              <Row as="dl">
                <Col as="dt" sm={2}>
                  Responded?
                </Col>
                <Col as="dd" sm={10}>
                  {batchResponse.responded ? 'Yes' : 'No'}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {batchResponse.responded &&
            accounts &&
            accounts.map(({ id, rowIndex, accountMessagesResponse }) => (
              <Card key={`accountResponse-${rowIndex}`} className="accountError">
                <Card.Header>
                  <div className="float-none">
                    <div className="float-left">
                      <h1 className="h4 mt-2">{`Response for Row ${rowIndex}`}</h1>
                    </div>
                    <div className="float-right">
                      <Button
                        variant="link"
                        onClick={accountResponseDetailClicked}
                        disabled={modalToggle}
                        data-id={id}
                      >
                        detail..
                      </Button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  {[]
                    .concat(_get(accountMessagesResponse, 'Message', []))
                    .map(({ InputPath, ErrorText }) => (
                      <Row as="dl" key={InputPath}>
                        {dataRow(InputPath, ErrorText)}
                      </Row>
                    ))}
                </Card.Body>
              </Card>
            ))}
        </Col>
      </Row>
    </>
  );
};

export default BatchShowBatchResponse;
