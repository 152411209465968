import React from 'react';
import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import { isInvalid, validationText } from './helpers';
import DropZone from './drop_zone';

export default function DropZoneField(props) {
  const {
    input,
    meta,
    groupStyle,
    groupClassName,
    labelWidth,
    inputWidth,
    helpText,
    helpBlock,
    filename,
    handleOnDrop,
  } = props;

  const invalid = isInvalid(meta);

  const innerContent = (
    <React.Fragment>
      {
        labelWidth === 0
          && props.children
          && <Form.Label>{props.children}</Form.Label>
      }
      <DropZone
        filename={filename}
        handleOnDrop={handleOnDrop}
      />
      {
        helpBlock && invalid && (
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {validationText(meta, helpText)}
          </Form.Control.Feedback>
        )
      }
      {
        helpBlock && helpText && !invalid && (
          <div style={{ width: '100%', marginTop: '0.25rem', fontSize: '80%' }}>
            {helpText}
          </div>
        )
      }
    </React.Fragment>
  );

  return (
    <Form.Group
      controlId={input.name}
      {... (groupStyle && { style: groupStyle })}
      {... (groupClassName && { className: groupClassName })}
      {... (labelWidth > 0 && { as: Row })}
      {... (labelWidth === 0 && { as: Col })}
    >
      {
        labelWidth > 0 ? (
          <React.Fragment>
            <Form.Label column sm={labelWidth}>{props.children}</Form.Label>
            <Col sm={inputWidth}>
              { innerContent }
            </Col>
          </React.Fragment>
        ) : (
          innerContent
        )
      }
    </Form.Group>
  );
}

DropZoneField.defaultProps = {
  labelWidth: 0,
  inputWidth: 12,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  plainText: false,
};
