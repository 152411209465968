
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BatchShowTypeBase"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BatchType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accounts"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AccountTypeBase"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"batchResponses"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BatchResponseTypeBase"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"accountResponses"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AccountResponseTypeBase"},"directives":[]}]}}]}}],"loc":{"start":0,"end":325}};
    doc.loc.source = {"body":"#import \"./account_type_base_fragment\"\n#import \"./batch_response_type_base_fragment\"\n#import \"./account_response_type_base_fragment\"\nfragment BatchShowTypeBase on BatchType {\n  accounts {\n    ...AccountTypeBase\n  }\n  batchResponses {\n    ...BatchResponseTypeBase\n  }\n  accountResponses {\n    ...AccountResponseTypeBase\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./account_type_base_fragment").definitions));
doc.definitions = doc.definitions.concat(unique(require("./batch_response_type_base_fragment").definitions));
doc.definitions = doc.definitions.concat(unique(require("./account_response_type_base_fragment").definitions));


      module.exports = doc;
    
