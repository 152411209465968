import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient } from 'react-apollo';
import { useHistory } from 'react-router-dom';

import { authReset } from '../actions/auth_actions';

export default function Logout() {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    apolloClient.resetStore();
    apolloClient.closeWSConnection();
    dispatch(authReset(history));
  }, []);

  return <p style={{ color: 'white' }}>Logging out...</p>;
}
