import {
  Sliders as SlidersIcon,
} from 'react-feather';

import Dashboard from '../containers/dashboard';
import BatchTemplateList from '../containers/batch_template_list';
import BatchTemplateForm from '../containers/batch_template_form';
import BatchList from '../containers/batch_list';
import BatchShow from '../containers/batch_show';
import BatchForm from '../containers/batch_form';
// import Blank from '../containers/blank';
import ReporterList from '../containers/reporter_list';
import ReporterShow from '../containers/reporter_show';
import ReporterForm from '../containers/reporter_form';

import UserList from '../containers/user_list';
import UserForm from '../containers/user_form';

export default {
  path: '/',
  name: 'Dashboard',
  icon: SlidersIcon,
  header: 'Main',
  children: [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      requireAuthenticated: true,
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      requireAuthenticated: true,
    },
    {
      path: '/batch_templates/new',
      name: 'Batch Template New',
      component: BatchTemplateForm,
      requireAuthenticated: true,
    },
    {
      path: '/batch_templates/:id/edit',
      name: 'Batch Template Edit',
      component: BatchTemplateForm,
      requireAuthenticated: true,
    },
    {
      path: '/batch_templates',
      name: 'Batch Templates',
      component: BatchTemplateList,
      requireAuthenticated: true,
      sidebar: true,
    },
    {
      path: '/batches/new',
      name: 'Batch New',
      component: BatchForm,
      requireAuthenticated: true,
    },
    {
      path: '/batches/:id/edit',
      name: 'Batch Edit',
      component: BatchForm,
      requireAuthenticated: true,
    },
    {
      path: '/batches/:id',
      name: 'Batch Show',
      component: BatchShow,
      requireAuthenticated: true,
    },
    {
      path: '/batches',
      name: 'Batches',
      component: BatchList,
      requireAuthenticated: true,
      sidebar: true,
    },

    {
      path: '/reporters/new',
      name: 'Reporter New',
      component: ReporterForm,
      requireAuthenticated: true,
    },
    {
      path: '/reporters/:id/edit',
      name: 'Reporter Edit',
      component: ReporterForm,
      requireAuthenticated: true,
    },
    {
      path: '/reporters/:id',
      name: 'Reporter Show',
      component: ReporterShow,
      requireAuthenticated: true,
    },
    {
      path: '/reporters',
      name: 'Reporters',
      component: ReporterList,
      requireAuthenticated: true,
      sidebar: true,
    },
    {
      path: '/users/new',
      name: 'User New',
      component: UserForm,
      requireAuthenticated: true,
    },
    {
      path: '/users/:id/edit',
      name: 'User Edit',
      component: UserForm,
      requireAuthenticated: true,
    },
    {
      path: '/users',
      name: 'Users',
      component: UserList,
      requireAuthenticated: true,
      sidebar: true,
    },
  ],
};
