const loginFormSchema = {
  type: 'object',
  required: ['email', 'password'],
  properties: {
    email: { type: 'string', format: 'email' },
    // password: { type: 'string', minLength: 6, maxLength: 255 },
  },
};

export default loginFormSchema;
