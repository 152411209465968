import React from 'react';

import Wrapper from '../components/layout/wrapper';
import Sidebar from '../components/layout/sidebar';
import Main from '../components/layout/main';
import Navbar from '../components/layout/navbar';
import Content from '../components/layout/content';
import Footer from '../components/layout/footer';

const Dashboard = ({ children }) => (
  <Wrapper>
    <Sidebar />
    <Main>
      <Navbar />
      <Content>{children}</Content>
      <Footer />
    </Main>
  </Wrapper>
);

export default Dashboard;
