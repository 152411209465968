export default [
  'id',
  'providerReference',
  'batchStatus',
  'batchType',
  'industryType',
  'mode',
  'version',
  'nameOfTheProvider',
  'notificationEmail',
  'mainContactEmail',
  'mainContactName',
  'mainContactPhone',
  'optionalContactEmail',
  'optionalContactName',
  'optionalContactPhone',
  'extractAt',
  'batchTemplateId',
  'fileFilename',
  'fileMimetype',
  'filePathname',
];
