import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'AUTH_FETCH',
  'AUTH_LOAD_SUCCESS',
  'AUTH_LOAD_FAILURE',
  'AUTH_RESET_SUCCESS',
  'AUTH_RESET_FAILURE',
  'SETTINGS_SET',
  'SIDEBAR_VISIBILITY_TOGGLE',
]);

export default actionTypes;
