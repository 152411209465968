import React from 'react';
import Loader from './loader';

const renderOverlay = (loading, settingsMutating = false) => {
  if (loading || settingsMutating) {
    return <Loader />;
  }
  return undefined;
};

export default renderOverlay;
