import React from 'react';
import { ToastContainer } from 'react-toastify';

import ErrorBoundaryApp from './components/error_boundary_app';
import ScrollToTop from './components/scroll_to_top';

import Routes from './routes';

// bootstrap
require('./css/corporate.css');
// toasts
require('react-toastify/dist/ReactToastify.css');
//
require('react-perfect-scrollbar/dist/css/styles.css');
//
// datetime picker
require('react-datetime/css/react-datetime.css');
require('./css/local.css');

const App = () => (
  <ErrorBoundaryApp>
    <ToastContainer />
    <ScrollToTop>
      <Routes />
    </ScrollToTop>
  </ErrorBoundaryApp>
);

export default App;
