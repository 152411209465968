/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Globe, Sliders as SlidersIcon, Users as UsersIcon } from 'react-feather';

import SidebarCategory from './sidebar_category';
import SidebarItem from './sidebar_item';

const AllRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    icon: SlidersIcon,
    open: true,
    header: 'Main',
    children: [
      {
        path: '/dashboard',
        name: 'Home',
      },
      {
        path: '/batches',
        name: 'Batches',
      },
      {
        path: '/batch_templates',
        name: 'Batch Templates',
      },
      {
        path: '/reporters',
        name: 'Credit Reporters',
      },
      {
        path: '/users',
        name: 'Users',
        admin: true,
      },
    ],
  },
  {
    path: '/auth',
    name: 'Auth',
    open: false,
    icon: UsersIcon,
    children: [
      {
        path: '/logout',
        name: 'Logout',
      },
    ],
  },
];

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderSidebarCategory = (category) => {
    const { name, badgeColor, badgeText, icon, path, open, children } = category;
    return (
      <SidebarCategory
        key={path}
        name={name}
        badgeColor={badgeColor}
        badgeText={badgeText}
        icon={icon}
        to={path}
        open={open}
        routerLocationPathname={this.props.routerLocationPathname}
      >
        {children.map((child) => this.renderSidebarItem(child))}
      </SidebarCategory>
    );
  };

  renderSidebarItem = (item) => {
    const { name, badgeColor, badgeText, icon, path, admin } = item;
    if (!admin || (admin && this.props.auth.user.admin)) {
      return (
        <SidebarItem
          key={path}
          name={name}
          badgeColor={badgeColor}
          badgeText={badgeText}
          icon={icon}
          to={path}
          routerLocationPathname={this.props.routerLocationPathname}
        />
      );
    }
    return undefined;
  };

  render() {
    const { settings } = this.props;
    return (
      <nav
        className={classNames('sidebar sidebar-sticky', settings.isOpenSidebar ? '' : 'toggled')}
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
            <LinkContainer to="/dashboard">
              <a className="sidebar-brand" href="/dashboard">
                <Globe className="align-middle text-primary mr-2" size={24} />
                &nbsp;
                <span className="align-middle">Happy Prime</span>
              </a>
            </LinkContainer>
            <ul className="sidebar-nav">
              {AllRoutes.map((category) => (
                <React.Fragment key={category.path}>
                  {category.header && <li className="sidebar-header">{category.header}</li>}
                  {category.children
                    ? this.renderSidebarCategory(category)
                    : this.renderSidebarItem(category)}
                </React.Fragment>
              ))}
            </ul>
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    settings: state.settings,
    routerLocationPathname: state.router.location.pathname,
  };
}

export default connect(mapStateToProps, {})(Sidebar);
