import _pickBy from 'lodash/pickBy';
import _cloneDeep from 'lodash/cloneDeep';
import _identity from 'lodash/identity';
import _compact from 'lodash/compact';

import Ajv from 'ajv';
import extendAjvErrors from 'ajv-errors';

const makeFormValidator = (schema) => {
  const ajv = new Ajv({
    allErrors: true,
    coerceTypes: true,
    $data: true,
    jsonPointers: true,
  });
  extendAjvErrors(ajv);
  const validator = ajv.compile(schema);
  return (values) => {
    const errors = {};
    const testValues = _pickBy(_cloneDeep(values), _identity);
    validator(testValues);
    if (validator.errors) {
      validator.errors.forEach((obj) => {
        let objKey;
        let dataPath = obj.dataPath.replace(/\//g, '.');
        dataPath = dataPath.replace(/^\./, '');
        if (obj.keyword === 'required') {
          objKey = _compact([dataPath, obj.params.missingProperty]).join('.');
        } else {
          objKey = dataPath;
        }
        if (objKey) {
          if (
            obj.message !== 'should match "then" schema' &&
            obj.message !== 'should match "else" schema'
          ) {
            if (objKey && errors[objKey]) {
              errors[objKey] = [...errors[objKey], obj.message];
            } else {
              errors[objKey] = [obj.message];
            }
          }
        }
      });
    }
    // console.log(errors);
    return errors;
  };
};

export default makeFormValidator;
