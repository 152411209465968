export default [
  'id',
  'reporterName',
  'username',
  'sftpUrl',
  'sftpUploadPath',
  'sftpDownloadPath',
  'active',
  'test',
  'encrypted',
  'signatoryId',
  'signatorySubId',
];
