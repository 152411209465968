import React from 'react';
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { LinkContainer } from 'react-router-bootstrap';

import { mutationSet, mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import { renderHocOverlay, renderHocError, renderHocPage } from '../components';

import reporterDeleteMutation from '../mutations/reporter_delete_mutation';
import pageReporterShowQuery from '../queries/page_reporter_show_query';

const ReporterShow = (props) => {
  const renderContent = () => {
    const {
      id,
      reporterName,
      username,
      sftpUrl,
      sftpUploadPath,
      sftpDownloadPath,
      active,
      test,
      encrypted,
      signatoryId,
      signatorySubId,
    } = props.pageQuery.reporter;

    return (
      <Container fluid className="p-0">
        <Row>
          <Col sm={12}>
            <div className="float-none">
              <div className="float-left">
                <h1 className="h3 mb-3">Show Credit Reporter</h1>
              </div>
              <div className="float-right">
                <LinkContainer to="/reporters">
                  <Button variant="primary">All Reporters</Button>
                </LinkContainer>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Details</Card.Title>
                <Table size="sm" className="my-2">
                  <thead>
                    <tr>
                      <td width="20%">Id:</td>
                      <td>{id}</td>
                    </tr>
                    <tr>
                      <td width="20%">Reporter Name:</td>
                      <td>{reporterName}</td>
                    </tr>
                    <tr>
                      <td width="20%">Signatory ID:</td>
                      <td>{signatoryId}</td>
                    </tr>
                    <tr>
                      <td width="20%">Signatory Sub ID:</td>
                      <td>{signatorySubId}</td>
                    </tr>
                    <tr>
                      <td width="20%">Secure FTP Url:</td>
                      <td>{sftpUrl}</td>
                    </tr>
                    <tr>
                      <td width="20%">Secure FTP Upload Path:</td>
                      <td>{sftpUploadPath}</td>
                    </tr>
                    <tr>
                      <td width="20%">Secure FTP Download Path:</td>
                      <td>{sftpDownloadPath}</td>
                    </tr>
                    <tr>
                      <td width="20%">Username:</td>
                      <td>{username}</td>
                    </tr>
                    <tr>
                      <td width="10%">Active:</td>
                      <td>{active ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                      <td width="10%">Encrypted:</td>
                      <td>{encrypted ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                      <td width="10%">Test:</td>
                      <td>{test ? 'Yes' : 'No'}</td>
                    </tr>
                  </thead>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div>
      {renderHocOverlay(props)}
      {renderHocError(props)}
      {renderHocPage(props) && renderContent()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    settingsMutating: state.settings.mutating,
  };
}

export default compose(
  graphql(reporterDeleteMutation, {
    name: 'reporterDeleteMutation',
  }),
  graphql(pageReporterShowQuery, {
    name: 'pageQuery',
    options: (props) => ({ variables: { id: props.match.params.id } }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  })
)(ReporterShow);
