import React from 'react';
import _get from 'lodash/get';

const renderHocError = (props, queryName = 'pageQuery') => {
  if (_get(props, [queryName, 'error'])) {
    return (
      <>
        <p>Something went wrong</p>
        {
          process.env.NODE_ENV !== 'production'
          && <pre>{JSON.stringify(_get(props, [queryName, 'error']), undefined, 2)}</pre>
        }
      </>
    );
  }
  return undefined;
};

export default renderHocError;
