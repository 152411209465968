import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import { isInvalid, validationText } from './helpers';

class CheckboxField extends Component {
  renderFormCheck(invalid) {
    const {
      noTab,
      disabled,
      inline,
      input,
      inputStyle,
      asElement,
      customOnChange,
      customOnBlur,
      size,
    } = this.props;

    const onBlur = customOnBlur ? customOnBlur(input.name, input.onBlur) : input.onBlur;
    const onChange = customOnChange ? customOnChange(input.name, input.onChange) : input.onChange;

    const commonProps = {
      type: input.type,
      checked: input.checked,
      ...(noTab && { tabIndex: -1 }),
      ...(asElement && { as: asElement }),
      ...(inputStyle && { style: inputStyle }),
      ...(invalid && { isInvalid: true }),
      ...(disabled && { disabled: true }),
      ...(inline && { inline: true, label: this.props.children }),
      ...(size && { className: `form-control-${size}` }),
    };
    return <Form.Check {...commonProps} onBlur={onBlur} onChange={onChange} />;
  }

  render() {
    const {
      input,
      meta,
      groupStyle,
      groupClassName,
      labelWidth,
      helpText,
      helpBlock,
      size,
      inline,
    } = this.props;

    const invalid = isInvalid(meta);

    const innerContent = (
      <>
        {labelWidth === 0 && !inline && this.props.children && (
          <Form.Label>{this.props.children}</Form.Label>
        )}
        {this.renderFormCheck(invalid)}
        {helpBlock && invalid && (
          <Form.Control.Feedback type="invalid">
            {validationText(meta, helpText)}
          </Form.Control.Feedback>
        )}
        {helpBlock && helpText && !invalid && (
          <div style={{ width: '100%', marginTop: '0.25rem', fontSize: '80%' }}>{helpText}</div>
        )}
      </>
    );

    return (
      <Form.Group
        controlId={input.name}
        {...(groupStyle && { style: groupStyle })}
        {...(groupClassName && { className: groupClassName })}
        {...(labelWidth > 0 && { as: Row })}
        {...(labelWidth === 0 && { as: Col })}
        // {...(labelWidth === 0 && inputWidth > 0 && { sm: inputWidth })}
      >
        {labelWidth > 0 ? (
          <>
            <Form.Label column={size || true} sm={labelWidth}>
              {this.props.children}
            </Form.Label>
            <Col sm={12 - labelWidth}>{innerContent}</Col>
          </>
        ) : (
          innerContent
        )}
      </Form.Group>
    );
  }
}

CheckboxField.defaultProps = {
  labelWidth: 0,
  inputWidth: 12,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  plainText: false,
};

export default CheckboxField;
