import jwtDecode from 'jwt-decode';
import { push } from 'connected-react-router';
import Api from '../lib/api';
import actionTypes from '../constants';

import { manageCatch, toastSuccess, toastError } from '../lib/action_helpers';

import { setIdToken, clearIdToken } from '../lib/local_storage';

export function authFetch() {
  return {
    type: actionTypes.AUTH_FETCH,
  };
}

export function authResetSuccess() {
  return {
    type: actionTypes.AUTH_RESET_SUCCESS,
  };
}

export function authResetFailure() {
  return {
    type: actionTypes.AUTH_RESET_FAILURE,
  };
}

export function authReset(history) {
  return function authResetDispatch(dispatch) {
    dispatch(authFetch());
    return Api.get('/auth/logout')
      .then(() => {
        clearIdToken();
        dispatch(authResetSuccess());
        history.push('/login');
        toastSuccess('Logout successful');
      })
      .catch((err) => {
        clearIdToken();
        history.push('/login');
        toastError('Logout unsuccessful');
        manageCatch(dispatch, err, authResetFailure);
      });
  };
}

export function authLoadSuccess(token, decoded) {
  return {
    type: actionTypes.AUTH_LOAD_SUCCESS,
    payload: {
      token,
      user: decoded.user,
      tenant: decoded.tenant,
    },
  };
}

export function authLoadFailure() {
  return {
    type: actionTypes.AUTH_LOAD_FAILURE,
  };
}

export function authLoad(email, password, redirect = '/', history) {
  return function authLoadDispatch(dispatch) {
    dispatch(authFetch());
    return Api.post('/auth/login', { email, password })
      .then((res) => {
        try {
          const decoded = jwtDecode(res.data.token);
          setIdToken(res.data.token);
          dispatch(authLoadSuccess(res.data.token, decoded));
          history.push(redirect);
          toastSuccess('Login successful');
        } catch (e) {
          clearIdToken();
          dispatch(authLoadFailure());
          history.push('/login');
          toastError('Login unsuccessful');
        }
      })
      .catch((res) => {
        manageCatch(dispatch, res, authLoadFailure);
      });
  };
}
