import React from 'react';
import classNames from 'classnames';

import { LinkContainer } from 'react-router-bootstrap';
import {
  Badge,
} from 'react-bootstrap';

const SidebarItem = ({
  name,
  badgeColor,
  badgeText,
  icon: Icon,
  routerLocationPathname,
  to,
}) => {
  const getSidebarItemClass = (path) => {
    if (routerLocationPathname === path) {
      return 'active';
    }
    return '';
  };

  return (
    <li className={classNames('sidebar-item', getSidebarItemClass(to))}>
      <LinkContainer to={to} className="sidebar-link" activeClassName="active">
        <span>
          {
            Icon && <Icon size={18} className="align-middle mr-3" />
          }
          { name }
          {
            badgeColor && badgeText && (
              <Badge color={badgeColor} size={18} className="sidebar-badge">
                {badgeText}
              </Badge>
            )
          }
        </span>
      </LinkContainer>
    </li>
  );
};

export default SidebarItem;
