import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Col,
  Card,
  Media,
  Row,
} from 'react-bootstrap';

import {
  AlertCircle,
  Activity,
  Calendar,
  Upload,
} from 'react-feather';

const Statistics = () => (
  <Row>
    <Col md="6" xl>
      <Card className="flex-fill">
        <Card.Body className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">
              <Activity className="feather-lg text-primary" />
            </div>
            <Media.Body>
              <h3 className="mb-2">7</h3>
              <div className="mb-0">Completed Batches</div>
            </Media.Body>
          </Media>
        </Card.Body>
      </Card>
    </Col>
    <Col md="6" xl>
      <Card className="flex-fill">
        <Card.Body className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">
              <Activity className="feather-lg text-warning" />
            </div>
            <Media.Body>
              <h3 className="mb-2">1</h3>
              <div className="mb-0">Pending Batches</div>
            </Media.Body>
          </Media>
        </Card.Body>
      </Card>
    </Col>
    <Col md="6" xl>
      <Card className="flex-fill">
        <Card.Body className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">
              <AlertCircle className="feather-lg text-danger" />
            </div>
            <Media.Body>
              <h3 className="mb-2">16</h3>
              <div className="mb-0">Unrevolved Errors</div>
            </Media.Body>
          </Media>
        </Card.Body>
      </Card>
    </Col>
    <Col md="6" xl>
      <Card className="flex-fill">
        <Card.Body className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">
              <Calendar className="feather-lg text-info" />
            </div>
            <Media.Body>
              <h3 className="mb-2">16/7</h3>
              <div className="mb-0">Next due batch date</div>
            </Media.Body>
          </Media>
        </Card.Body>
      </Card>
    </Col>
    {/* <Col md="6" xl className="d-none d-xxl-flex"> */}
    <Col md="6" xl>
      <LinkContainer to="/batches/new">
        <Card id="dashboard-new-upload" className="flex-fill" bg="primary">
          <Card.Body className="py-4">
            <Media>
              <div className="d-inline-block mt-2 mr-3">
                <Upload className="feather-lg text-white" />
              </div>
              <Media.Body>
                <h3 className="mt-3 text-white">New Batch</h3>
              </Media.Body>
            </Media>
          </Card.Body>
        </Card>
      </LinkContainer>
    </Col>
  </Row>
);

export default Statistics;
