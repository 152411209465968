import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import Statistics from '../components/dashboard/statistics';
import LineChart from '../components/dashboard/line_chart';
import BarChart from '../components/dashboard/bar_chart';

const Dashboard = () => (
  <Container fluid className="p-0">
    <h2>Sample Charts and Data</h2>
    <Statistics />
    <Row>
      <Col lg="8" className="d-flex">
        <LineChart />
      </Col>
      <Col lg="4" className="d-flex">
        <BarChart />
      </Col>
    </Row>
  </Container>
);

export default Dashboard;
