import { Card, Row, Col, Form, FormGroup, Button } from 'react-bootstrap';
import { Form as FinalForm, Field } from 'react-final-form';

import React, { useEffect } from 'react';
import { useApolloClient } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import _get from 'lodash/get';
import { authLoad } from '../actions/auth_actions';
import SubmitButtonSpinner from '../components/submit_button_spinner';

import InputField from '../components/form/rff_input_field';
import { loginFormValidator } from '../validators';

import logoWhite from '../images/logo-white.png';

export default function Login() {
  const isAuthenticating = useSelector((state) => state.auth.isAuthenticating);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const history = useHistory();
  const location = useLocation();
  let redirectPath = null;
  if (location.search) {
    redirectPath = _get(qs.parse(location.search, { ignoreQueryPrefix: true }), 'redirect', null);
  }

  useEffect(() => {
    if (isAuthenticated) {
      history.push(redirectPath || '/dashboard');
    } else {
      apolloClient.closeWSConnection();
    }
  }, []);

  const handleFormSubmit = (data) => {
    dispatch(authLoad(data.email, data.password, redirectPath || '/', history)).then(() => {
      apolloClient.openWSConnection();
    });
  };

  return (
    <>
      <div className="text-center mt-4">
        <h2>Welcome to Happy Prime Importer</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center mb-4">
              <img
                src={logoWhite}
                alt="Happy Prime"
                className="img-fluid rounded-circle bg-secondary mb-4"
                width="64"
                height="64"
              />
            </div>
            <input style={{ display: 'none' }} type="text" name="fakeemailremembered" />
            <input style={{ display: 'none' }} type="password" name="fakepasswordremembered" />
            <FinalForm onSubmit={handleFormSubmit} validate={loginFormValidator}>
              {({ handleSubmit, submitting, pristine, invalid }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    name="email"
                    autoCapitalize="none"
                    component={InputField}
                    helpText="&nbsp;"
                  >
                    Email
                  </Field>
                  <Field
                    type="password"
                    name="password"
                    autoCapitalize="none"
                    component={InputField}
                    helpText="&nbsp;"
                  >
                    Password
                  </Field>
                  <FormGroup as={Row}>
                    <Col sm={12}>
                      <div className="float-right">
                        <Button
                          className="float-right"
                          type="submit"
                          variant="primary"
                          disabled={pristine || invalid || submitting}
                        >
                          {(isAuthenticating || submitting) && <SubmitButtonSpinner />}
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </FormGroup>
                </Form>
              )}
            </FinalForm>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
