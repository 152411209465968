import { Form, FormGroup, Button, Card, Col, Container, Row, ButtonGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import {
  reduxForm,
  Field,
  getFormValues,
  change as changeFieldValue,
  initialize as initializeForm,
  reset as resetForm,
} from 'redux-form';
import Spinner from 'react-spinkit';

import _cloneDeep from 'lodash/cloneDeep';
import _difference from 'lodash/difference';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import _merge from 'lodash/merge';
import _omitBy from 'lodash/omitBy';
import _pick from 'lodash/pick';

import { mutationSet, mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import Loader from '../components/loader';
import DropZoneField from '../components/form/drop_zone_field';
import InputField from '../components/form/input_field';
import ReactDateTimeField from '../components/form/react_date_time_field';

import { coerceInput } from '../lib/utils';

import batchCreateMutation from '../mutations/batch_create_mutation';
import batchUpdateMutation from '../mutations/batch_update_mutation';

import batchQuery from '../queries/batch_query';
import batchListQuery from '../queries/batch_list_query';
import pageBatchFormQuery from '../queries/page_batch_form_query';

import { BatchTypes, IndustryTypes, Modes } from '../schemas/tables';

import makeFormValidator from '../lib/make_form_validator';

import { batchFormSchema } from '../schemas';

import batchWhiteList from '../constants/batch_white_list';
import batchTemplateWhiteList from '../constants/batch_template_white_list';

let isInitialisedBatchForm = false;

moment.locale('en-nz');

class BatchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.match.params.id,
      dateTimeErrors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleBatchTemplateIdChange = this.handleBatchTemplateIdChange.bind(this);
  }

  componentWillUnmount() {
    isInitialisedBatchForm = false;
  }

  canRenderLoader = () => !!(this.props.settingsMutating || this.props.pageQuery.loading);

  canRenderContent = () =>
    !!(
      isInitialisedBatchForm &&
      !this.props.pageQuery.loading &&
      (!this.state.updating || _get(this.props, 'formValues.id'))
    );

  validDateTimes() {
    return Object.values(this.state.dateTimeErrors).every((e) => !e);
  }

  handleOnDrop(file) {
    this.props.changeFieldValue(this.props.form, 'file', file[0]);
  }

  handleDateTimeChange(date, field) {
    const validDate = moment.isMoment(date);
    if (validDate) {
      this.props.changeFieldValue(this.props.form, field, date.clone().format());
    }
    this.setState((prevState) => ({
      dateTimeErrors: _merge({}, prevState.dateTimeErrors, { [field]: !validDate }),
    }));
  }

  handleBatchTemplateIdChange(e) {
    const batchTemplateId = e.currentTarget.value;
    let initialValues = _pick(_get(this.props, 'formValues'), [
      'id',
      'extractAt',
      'fileFilename',
      'fileMimetype',
      'filePathname',
      'file',
    ]);
    initialValues.batchTemplateId = batchTemplateId;
    if (initialValues.batchTemplateId) {
      const batchTemplate = this.props.pageQuery.batchTemplateList.find(
        (bt) => bt.id === parseInt(initialValues.batchTemplateId, 10)
      );
      const whiteList = _difference(batchTemplateWhiteList, ['id', 'description']);
      initialValues = _merge(
        {},
        _get(this.props, 'formValues'),
        { batchTemplateId },
        _omitBy(_pick(batchTemplate, whiteList), _isNil)
      );
    }
    this.props.initializeForm('BatchForm', initialValues, {
      keepDirty: false,
      updateUnregisteredFields: true,
    });
  }

  handleSubmit(data) {
    const submitData = _cloneDeep(data);
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: coerceInput(submitData) },
      context: { hasUpload: true },
      refetchQueries: () => [{ query: batchListQuery }],
    };
    if (this.state.updating) {
      mutation = this.props.batchUpdateMutation;
      mutationMessage = 'Batch update';
      mutationData.variables.id = this.props.match.params.id;
    } else {
      mutation = this.props.batchCreateMutation;
      mutationMessage = 'Batch create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage, '/batches');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  renderTitle = () => {
    if (this.state.updating) {
      return 'Edit Batch';
    } else {
      return 'New Batch';
    }
  };

  renderContent() {
    const { handleSubmit, submitting, pageQuery, formValues } = this.props;

    const batchTemplateId = _get(this.props, 'formValues.batchTemplateId');
    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{this.renderTitle()}</h1>
        <Card>
          <Card.Body>
            <Form noValidate onSubmit={handleSubmit(this.handleSubmit)}>
              <Form.Row>
                <Field
                  name="extractAt"
                  component={ReactDateTimeField}
                  helpText="DD/MM/YYYY HH:mm"
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  handleDateTimeChange={this.handleDateTimeChange}
                >
                  Extracted At
                </Field>
                <Field
                  type="text"
                  name="batchTemplateId"
                  component={InputField}
                  asElement="select"
                  selectOptions={_map(pageQuery.batchTemplateList, ({ id, description }) => ({
                    id,
                    name: description,
                  }))}
                  customOnChange={this.handleBatchTemplateIdChange}
                >
                  Batch Template
                </Field>
                <Col>
                  {batchTemplateId && (
                    <Form.Row>
                      <Field
                        type="text"
                        name="batchType"
                        component={InputField}
                        asElement="select"
                        selectOptions={_map(BatchTypes, (name, id) => ({ id, name }))}
                      >
                        Batch Type
                      </Field>
                      <Field
                        type="text"
                        name="industryType"
                        component={InputField}
                        asElement="select"
                        selectOptions={_map(IndustryTypes, (name, id) => ({ id, name }))}
                      >
                        Industry Type
                      </Field>
                      <Field
                        type="text"
                        name="mode"
                        component={InputField}
                        asElement="select"
                        selectOptions={_map(Modes, (name, id) => ({ id, name }))}
                      >
                        Mode
                      </Field>
                    </Form.Row>
                  )}
                </Col>
              </Form.Row>
              {batchTemplateId && (
                <>
                  <Form.Row>
                    <Field type="text" name="nameOfTheProvider" component={InputField}>
                      Provider Name
                    </Field>
                    <Col>
                      <Form.Row>
                        <Field type="text" name="providerReference" component={InputField}>
                          Provider Reference
                        </Field>
                        <Field
                          type="text"
                          name="version"
                          component={InputField}
                          plainText
                          groupClassName="col-3"
                        >
                          API Version
                        </Field>
                      </Form.Row>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Field type="text" name="notificationEmail" component={InputField}>
                      Notification Email
                    </Field>
                  </Form.Row>
                  <Form.Row>
                    <Field type="text" name="mainContactEmail" component={InputField}>
                      Main Contact Email
                    </Field>
                    <Field type="text" name="mainContactName" component={InputField}>
                      Main Contact Name
                    </Field>
                    <Field type="text" name="mainContactPhone" component={InputField}>
                      Main Contact Phone
                    </Field>
                  </Form.Row>
                  <Form.Row>
                    <Field type="text" name="optionalContactEmail" component={InputField}>
                      Optional Contact Email
                    </Field>
                    <Field type="text" name="optionalContactName" component={InputField}>
                      Optional Contact Name
                    </Field>
                    <Field type="text" name="optionalContactPhone" component={InputField}>
                      Optional Contact Phone
                    </Field>
                  </Form.Row>
                </>
              )}
              {batchTemplateId && (
                <>
                  <Form.Row>
                    <Field
                      type="text"
                      name="file"
                      component={DropZoneField}
                      filename={formValues.file ? formValues.file.name : formValues.fileFilename}
                      handleOnDrop={this.handleOnDrop}
                    />
                  </Form.Row>
                </>
              )}
              <FormGroup as={Row}>
                <Col sm={12}>
                  <ButtonGroup className="float-right">
                    <LinkContainer to="/batches">
                      <Button type="reset" variant="danger" disabled={submitting}>
                        Cancel
                      </Button>
                    </LinkContainer>
                    <Button
                      className="float-right"
                      type="submit"
                      variant="primary"
                      disabled={submitting || !this.validDateTimes()}
                    >
                      {submitting && (
                        <Spinner
                          fadeIn="quarter"
                          name="circle"
                          color="white"
                          style={{
                            display: 'inline-block',
                            width: '0.75rem',
                            height: '0.75rem',
                            marginRight: '0.625rem',
                          }}
                        />
                      )}
                      {this.state.updating ? 'Update' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </Col>
              </FormGroup>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  render() {
    return (
      <div>
        {this.canRenderLoader() && <Loader />}
        {this.canRenderContent() && this.renderContent()}
      </div>
    );
  }
}

function pickInitialValues(initialQuery, updating) {
  if (!isInitialisedBatchForm) {
    if (!updating) {
      isInitialisedBatchForm = true;
      return {
        batchStatus: 'UNPROCESSED',
        extractAt: moment().format(),
      };
    } else if (!initialQuery.loading) {
      isInitialisedBatchForm = true;
      const data = _pick(_omitBy(_cloneDeep(initialQuery.batch), _isNil), batchWhiteList);
      return data;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.batchQuery, !!props.match.params.id);
  return {
    initialValues,
    formValues: getFormValues('BatchForm')(state),
    settingsMutating: state.settings.mutating,
  };
}

export default compose(
  graphql(batchCreateMutation, {
    name: 'batchCreateMutation',
  }),
  graphql(batchUpdateMutation, {
    name: 'batchUpdateMutation',
  }),
  graphql(pageBatchFormQuery, {
    name: 'pageQuery',
  }),
  graphql(batchQuery, {
    name: 'batchQuery',
    skip: (props) => !props.match.params.id,
    options: (props) => ({ variables: { id: props.match.params.id }, fetchPolicy: 'network-only' }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    initializeForm,
    resetForm,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'BatchForm',
    validate: makeFormValidator(batchFormSchema),
  })
)(BatchForm);
