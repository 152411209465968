import { Form, FormGroup, Button, Card, Col, Container, Row, ButtonGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { LinkContainer } from 'react-router-bootstrap';
import { reduxForm, Field, getFormValues, change as changeFieldValue } from 'redux-form';
import Spinner from 'react-spinkit';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import _omitBy from 'lodash/omitBy';
import _pick from 'lodash/pick';

import { mutationSet, mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import Loader from '../components/loader';
import InputField from '../components/form/input_field';

import batchTemplateWhiteList from '../constants/batch_template_white_list';

import { coerceInput } from '../lib/utils';

import batchTemplateCreateMutation from '../mutations/batch_template_create_mutation';
import batchTemplateUpdateMutation from '../mutations/batch_template_update_mutation';

import batchTemplateQuery from '../queries/batch_template_query';
import batchTemplateListQuery from '../queries/batch_template_list_query';

import { BatchTypes, IndustryTypes, Modes } from '../schemas/tables';

import makeFormValidator from '../lib/make_form_validator';

import { batchTemplateFormSchema } from '../schemas';

let isInitialisedBatchTemplateForm = false;

class BatchTemplateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.match.params.id,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    isInitialisedBatchTemplateForm = false;
  }

  canRenderLoader = () =>
    this.props.settingsMutating;
    // || this.props.pageQuery.loading

  canRenderContent = () =>
    isInitialisedBatchTemplateForm &&
    // && !this.props.pageQuery.loading
    (!this.state.updating || _get(this.props, 'formValues.id'));

  handleSubmit(data) {
    const submitData = _cloneDeep(data);
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: coerceInput(submitData) },
      refetchQueries: () => [{ query: batchTemplateListQuery }],
    };
    if (this.state.updating) {
      mutation = this.props.batchTemplateUpdateMutation;
      mutationMessage = 'Batch template update';
      mutationData.variables.id = this.props.match.params.id;
    } else {
      mutation = this.props.batchTemplateCreateMutation;
      mutationMessage = 'Batch Template create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage, '/batch_templates');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  renderTitle = () => {
    if (this.state.updating) {
      return 'Edit Batch Template';
    } else {
      return 'New Batch Template';
    }
  };

  renderContent() {
    const { handleSubmit, submitting } = this.props;
    console.log(this.props);
    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{this.renderTitle()}</h1>
        <Card>
          <Card.Body>
            <Form noValidate onSubmit={handleSubmit(this.handleSubmit)}>
              <Form.Row>
                <Field type="text" name="description" component={InputField}>
                  Description
                </Field>
                <Col>
                  <Form.Row>
                    <Field
                      type="text"
                      name="batchType"
                      component={InputField}
                      asElement="select"
                      defaultSelectOption={false}
                      selectOptions={_map(BatchTypes, (name, id) => ({ id, name }))}
                    >
                      Batch Type
                    </Field>
                    <Field
                      type="text"
                      name="industryType"
                      component={InputField}
                      asElement="select"
                      defaultSelectOption={false}
                      selectOptions={_map(IndustryTypes, (name, id) => ({ id, name }))}
                    >
                      Industry Type
                    </Field>
                    <Field
                      type="text"
                      name="mode"
                      component={InputField}
                      asElement="select"
                      defaultSelectOption={false}
                      selectOptions={_map(Modes, (name, id) => ({ id, name }))}
                    >
                      Mode
                    </Field>
                  </Form.Row>
                </Col>
              </Form.Row>
              <Form.Row>
                <Field type="text" name="nameOfTheProvider" component={InputField}>
                  Provider Name
                </Field>
                <Col>
                  <Form.Row>
                    <Field type="text" name="providerReference" component={InputField}>
                      Provider Reference
                    </Field>
                    <Field
                      type="text"
                      name="version"
                      component={InputField}
                      plainText={_get(this.props.formValues, 'version')}
                      groupClassName="col-3"
                    >
                      API Version
                    </Field>
                  </Form.Row>
                </Col>
              </Form.Row>
              <Form.Row>
                <Field type="text" name="notificationEmail" component={InputField}>
                  Notification Email
                </Field>
              </Form.Row>
              <Form.Row>
                <Field type="text" name="mainContactEmail" component={InputField}>
                  Main Contact Email
                </Field>
                <Field type="text" name="mainContactName" component={InputField}>
                  Main Contact Name
                </Field>
                <Field type="text" name="mainContactPhone" component={InputField}>
                  Main Contact Phone
                </Field>
              </Form.Row>
              <Form.Row>
                <Field type="text" name="optionalContactEmail" component={InputField}>
                  Optional Contact Email
                </Field>
                <Field type="text" name="optionalContactName" component={InputField}>
                  Optional Contact Name
                </Field>
                <Field type="text" name="optionalContactPhone" component={InputField}>
                  Optional Contact Phone
                </Field>
              </Form.Row>
              <FormGroup as={Row}>
                <Col sm={12}>
                  <ButtonGroup className="float-right">
                    <LinkContainer to="/batch_templates">
                      <Button type="reset" variant="danger" disabled={submitting}>
                        Cancel
                      </Button>
                    </LinkContainer>
                    <Button
                      className="float-right"
                      type="submit"
                      variant="primary"
                      disabled={submitting}
                    >
                      {submitting && (
                        <Spinner
                          fadeIn="quarter"
                          name="circle"
                          color="white"
                          style={{
                            display: 'inline-block',
                            width: '0.75rem',
                            height: '0.75rem',
                            marginRight: '0.625rem',
                          }}
                        />
                      )}
                      {this.state.updating ? 'Update' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </Col>
              </FormGroup>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  render() {
    return (
      <div>
        {this.canRenderLoader() && <Loader />}
        {this.canRenderContent() && this.renderContent()}
      </div>
    );
  }
}

function pickInitialValues(initialQuery, updating) {
  if (!isInitialisedBatchTemplateForm) {
    if (!updating) {
      isInitialisedBatchTemplateForm = true;
      return {
        batchType: 'I',
        industryType: 'F',
        mode: 'T',
        version: '3.01',
        nameOfTheProvider: 'Admin Loans Limited',
      };
    } else if (!initialQuery.loading) {
      isInitialisedBatchTemplateForm = true;
      const data = _pick(
        _omitBy(_cloneDeep(initialQuery.batchTemplate), _isNil),
        batchTemplateWhiteList
      );
      return data;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.batchTemplateQuery, !!props.match.params.id);
  return {
    initialValues,
    formValues: getFormValues('BatchTemplateForm')(state),
    settingsMutating: state.settings.mutating,
  };
}

export default compose(
  graphql(batchTemplateCreateMutation, {
    name: 'batchTemplateCreateMutation',
  }),
  graphql(batchTemplateUpdateMutation, {
    name: 'batchTemplateUpdateMutation',
  }),
  graphql(batchTemplateQuery, {
    name: 'batchTemplateQuery',
    skip: (props) => !props.match.params.id,
    options: (props) => ({ variables: { id: props.match.params.id }, fetchPolicy: 'network-only' }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'BatchTemplateForm',
    validate: makeFormValidator(batchTemplateFormSchema),
  })
)(BatchTemplateForm);
