/* eslint-disable no-useless-escape */
import _merge from 'lodash/merge';
import _without from 'lodash/without';

import { batchTemplateSchema } from './batch_template_schema';

const batchSchema = _merge(
  {},
  batchTemplateSchema,
  {
    type: 'object',
    required: [
      'extractAt',
      'batchTemplateId',
    ],
    properties: {
      extractAt: {
        type: 'string', format: 'date-time',
      },
      batchTemplateId: {
        type: 'integer', minLength: 10, maxLength: 20,
      },
    },
  },
);

const batchFormSchema = _merge(
  {},
  batchSchema,
  {
    type: 'object',
    oneOf: [
      {
        required: [
          'file',
        ],
      },
      {
        required: [
          'fileFilename',
        ],
      },
    ],
  },
);

// include virtual attributes as well when submitting to credit reporters
// todo remove extractAt and batchTemplateId from required?
const batchSubmissionSchema = _merge(
  {},
  batchFormSchema,
  {
    type: 'object',
    required: [
      'batchId',
      'extractDate',
      'extractTime',
    ],
    properties: {
      batchId: {
        type: 'string', minLength: 10, maxLength: 20,
      },
      extractDate: {
        type: 'string', minLength: 10, maxLength: 10, pattern: '^\d{4}-\d{2}-\d{2}$',
      }, // ccyy-mm-dd
      extractTime: {
        type: 'string', minLength: 8, maxLength: 8, pattern: '^\d{2}:\d{2}:\d{2}$',
      }, // hh:mm:ss
    },
  },
);

batchSubmissionSchema.required = _without(batchSubmissionSchema.required, ['extractAt', 'batchTemplateId']);
delete batchSubmissionSchema.oneOf;

export {
  batchSchema,
  batchFormSchema,
  batchSubmissionSchema,
};
