/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { Navbar, Nav, Dropdown } from 'react-bootstrap';

import { User as UserIcon } from 'react-feather';

import { toggleSidebar } from '../../actions/sidebar_actions';

import avatar from '../../images/avatar.jpg';

const NavbarComponent = (props) => (
  <Navbar bg="white" fixed expand>
    <span className="sidebar-toggle d-flex mr-2" onClick={() => props.toggleSidebar()}>
      <i className="hamburger align-self-center" />
    </span>

    <Navbar.Collapse>
      <Nav className="ml-auto" navbar>
        <Dropdown navbar>
          <Dropdown.Toggle>
            <img
              src={avatar}
              className="avatar img-fluid rounded-circle mr-1"
              alt={props.auth.user.email}
            />
            <span className="text-white mr-2">{props.auth.user.email}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <UserIcon size={18} className="align-middle mr-2" />
              Profile
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>Settings</Dropdown.Item>
            <LinkContainer to={{ pathname: '/logout' }}>
              <Dropdown.Item>Sign Out</Dropdown.Item>
            </LinkContainer>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { toggleSidebar })(NavbarComponent);
