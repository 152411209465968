import React from 'react';
import { Button, Col, Container, Row, Tabs, Tab } from 'react-bootstrap';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import _groupBy from 'lodash/groupBy';

import { renderOverlay, renderError, renderPage } from '../components';

import BatchShowBatchDetail from '../components/batch_show/batch_detail';
import BatchShowJson from '../components/batch_show/json';
import BatchShowBatchResponse from '../components/batch_show/batch_response';
import BatchShowAccountErrors from '../components/batch_show/account_errors';

import pageBatchShowQuery from '../queries/page_batch_show_query';

import { BatchStatuses, BatchTypes, IndustryTypes, Modes } from '../schemas/tables';

const BatchShow = () => {
  const user = useSelector((state) => state.auth.user);
  const params = useParams();

  const { data: pageData, loading: pageLoading, error: pageError } = useQuery(pageBatchShowQuery, {
    variables: { batchId: params.id },
  });

  const renderContent = () => {
    const {
      importErrors,
      insertErrors,
      accounts,
      batchResponses,
      accountResponses,
    } = pageData.batch;

    const groupedAccountResponses = _groupBy(accountResponses, (ar) => ar.reporterId);

    return (
      <Container fluid className="p-0">
        <Row>
          <Col sm={12}>
            <div className="float-none">
              <div className="float-left">
                <h1 className="h3 mb-3">Batch Information</h1>
              </div>
              <div className="float-right">
                <LinkContainer to="/batches">
                  <Button variant="primary">All batches</Button>
                </LinkContainer>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs defaultActiveKey="detail">
              <Tab eventKey="detail" title="Overview">
                <BatchShowBatchDetail
                  batch={pageData.batch}
                  BatchStatuses={BatchStatuses}
                  BatchTypes={BatchTypes}
                  IndustryTypes={IndustryTypes}
                  Modes={Modes}
                />
              </Tab>
              {/* {user.developer && (
                <Tab eventKey="xml" title="XML">
                  <BatchShowXml xml={xml} />
                </Tab>
              )} */}
              {user.developer && (
                <Tab eventKey="importErrors" title="Import Errors">
                  <BatchShowJson json={importErrors} header="CSV Import Errors" />
                </Tab>
              )}
              {user.developer && (
                <Tab eventKey="insertErrors" title="Insert Errors">
                  <BatchShowJson json={insertErrors} header="Account Insert Errors" />
                </Tab>
              )}
              <Tab eventKey="accountErrors" title="Account Errors">
                <BatchShowAccountErrors accounts={accounts} developer={user.developer} />
              </Tab>
              {batchResponses.map((batchResponse) => {
                const reporter = pageData.reporterList.find(
                  (r) => r.id === batchResponse.reporterId
                );
                return (
                  <Tab
                    key={`accountResponses-${reporter.reporterName}`}
                    eventKey={`accountResponses-${reporter.reporterName}`}
                    title={reporter.reporterName}
                  >
                    <BatchShowBatchResponse
                      batchResponse={batchResponse}
                      reporter={reporter}
                      accounts={groupedAccountResponses[reporter.id]}
                    />
                  </Tab>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div>
      <div>
        {renderOverlay(pageLoading)}
        {renderError(pageError)}
        {renderPage(pageLoading, pageError) && renderContent()}
      </div>
    </div>
  );
};

export default BatchShow;
