import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';

import { isInvalid, validationText } from './helpers';

class CheckboxField extends Component {
  renderFormCheck() {
    const {
      meta,
      noTab,
      disabled,
      inline,
      type,
      input,
      inputStyle,
      asElement,
      customOnChange,
      customOnBlur,
    } = this.props;

    const invalid = isInvalid(meta);
    const onBlur = customOnBlur || input.onBlur;
    const onChange = customOnChange || input.onChange;

    const commonProps = {
      type,
      checked: input.value,
      ...(noTab && { tabIndex: -1 }),
      ...(asElement && { as: asElement }),
      ...(inputStyle && { style: inputStyle }),
      ...(invalid && { isInvalid: true }),
      ...(disabled && { disabled: true }),
      ...(inline && { inline: true }),
    };
    return (
      <Form.Check
        {...commonProps}
        onBlur={onBlur}
        onChange={onChange}
      />
    );
  }

  render() {
    const {
      input,
      meta,
      groupStyle,
      groupClassName,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
    } = this.props;

    const invalid = isInvalid(meta);

    const innerContent = (
      <>
        {
          labelWidth === 0
            && this.props.children
            && <Form.Label>{this.props.children}</Form.Label>
        }
        {
          this.renderFormCheck()
        }
        {
          helpBlock && invalid && (
            <Form.Control.Feedback type="invalid">
              {validationText(meta, helpText)}
            </Form.Control.Feedback>
          )
        }
        {
          helpBlock && helpText && !invalid && (
            <div style={{ width: '100%', marginTop: '0.25rem', fontSize: '80%' }}>
              {helpText}
            </div>
          )
        }

      </>
    );

    return (
      <Form.Group
        controlId={input.name}
        {... (groupStyle && { style: groupStyle })}
        {... (groupClassName && { className: groupClassName })}
        {... (labelWidth > 0 && { as: Row })}
        {... (labelWidth === 0 && { as: Col })}
      >
        {
          labelWidth > 0 ? (
            <>
              <Form.Label column sm={labelWidth}>{this.props.children}</Form.Label>
              <Col sm={inputWidth}>
                { innerContent }
              </Col>
            </>
          ) : (
            innerContent
          )
        }
      </Form.Group>
    );
  }
}

CheckboxField.defaultProps = {
  labelWidth: 0,
  inputWidth: 12,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  plainText: false,
};

export default CheckboxField;
