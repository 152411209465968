import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import BootstrapTable from 'react-bootstrap-table-next';
import { LinkContainer } from 'react-router-bootstrap';

import { mutationSet, mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import { Confirm, renderHocOverlay, renderHocError, renderHocPage } from '../components';

import reporterDeleteMutation from '../mutations/reporter_delete_mutation';
import pageReporterListQuery from '../queries/page_reporter_list_query';

const ReporterList = (props) => {
  const handleDeleteClick = (e) => {
    props.mutationSet(true);
    const reporterId = e.currentTarget.getAttribute('data-id');
    props
      .reporterDeleteMutation({
        variables: {
          id: reporterId,
        },
        refetchQueries: () => [{ query: pageReporterListQuery }],
      })
      .then(() => {
        props.mutationSuccess('Reporter deleted');
      })
      .catch((res) => {
        props.mutationFailure(res);
      });
  };

  const renderActionsCell = (cell, row) => (
    <>
      <LinkContainer to={`/reporters/${row.id}/edit`}>
        <Button variant="link" size="small">
          edit
        </Button>
      </LinkContainer>
      <br />
      <LinkContainer to={`/reporters/${row.id}`}>
        <Button variant="link" size="small">
          show
        </Button>
      </LinkContainer>
      <br />
      <Confirm
        confirmId={`reporter-delete-${row.id}`}
        dataId={row.id}
        // disabled={checkStatus === 'Completed'} // todo no delete if has upload records?
        onConfirm={handleDeleteClick}
        title="Delete Reporter"
        body="Are you sure you want to delete this credit reporter"
        confirmText="Confirm"
      >
        <Button
          variant="link"
          size="xsmall"
          // disabled={checkStatus === 'Completed'}
        >
          delete
        </Button>
      </Confirm>
    </>
  );

  const renderBooleanCell = (bool) => (bool ? 'Yes' : 'No');

  const tableColumns = [
    {
      dataField: 'reporterName',
      text: 'Reporter Name',
    },
    {
      dataField: 'sftpUrl',
      text: 'Reporter SFTP',
    },
    {
      dataField: 'sftpUploadPath',
      text: 'Reporter SFTP Upload Path',
    },
    {
      dataField: 'sftpDownloadPath',
      text: 'Reporter SFTP Download Path',
    },
    {
      dataField: 'username',
      text: 'Username',
    },
    {
      dataField: 'active',
      text: 'Active',
      formatter: renderBooleanCell,
    },
    {
      dataField: 'encrypted',
      text: 'Encrypted',
      formatter: renderBooleanCell,
    },
    {
      dataField: 'test',
      text: 'Test',
      formatter: renderBooleanCell,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      formatter: renderActionsCell,
    },
  ];

  const renderContent = () => (
    <Container fluid className="p-0">
      <Row>
        <Col sm={12}>
          <div className="float-none">
            <div className="float-left">
              <h1 className="h3 mb-3">Credit Reporters</h1>
            </div>
            <div className="float-right">
              <LinkContainer to="/reporters/new">
                <Button variant="primary">Add a Reporter</Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                data={props.pageQuery.reporterList}
                columns={tableColumns}
                bootstrap4
                bordered={false}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return (
    <div>
      {renderHocOverlay(props)}
      {renderHocError(props)}
      {renderHocPage(props) && renderContent()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    settingsMutating: state.settings.mutating,
  };
}

export default compose(
  graphql(reporterDeleteMutation, {
    name: 'reporterDeleteMutation',
  }),
  graphql(pageReporterListQuery, {
    name: 'pageQuery',
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  })
)(ReporterList);
