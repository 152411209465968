/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Card, Col, Row, Button, Modal, Tabs, Tab, Table } from 'react-bootstrap';

import _get from 'lodash/get';
import _keys from 'lodash/keys';
import _last from 'lodash/last';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';

import BatchShowJson from './json';

const BatchShowAccountErrors = ({ accounts }) => {
  const [modalToggle, setModalToggle] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});

  const handleSetModalToggle = () => {
    if (modalToggle) {
      setSelectedAccount({});
    }
    setModalToggle(!modalToggle);
  };

  const accountDetailClicked = (e) => {
    e.preventDefault();
    const accountId = parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const account = accounts.find((ac) => ac.id === accountId);
    setSelectedAccount(account);
    handleSetModalToggle();
  };

  const dataRow = (dt, dd = null, dtW = 2, ddW = 10) => (
    <>
      <Col as="dt" sm={dtW}>
        {`${_last(dt.split('.'))}:`}
      </Col>
      <Col as="dd" sm={ddW}>
        {dd ? (
          Array.isArray(dd) ? (
            dd.map((d, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={`${dt}${index}`}>
                {d}
                <br />
              </span>
            ))
          ) : (
            dd
          )
        ) : (
          <span className="text-muted">-</span>
        )}
      </Col>
    </>
  );

  const sortedTransformedRowKeys = _sortBy(_keys(_get(accounts, [0, 'transformedRow'], {})));

  const renderTransformedRow = (row) => (
    <Table>
      <thead>
        <tr>
          {sortedTransformedRowKeys.map((key) => (
            <th key={`th-${key}-${row.customerId}`}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {sortedTransformedRowKeys.map((key) => (
            <td key={`td-${key}-${row.customerId}`} style={{ whiteSpace: 'nowrap' }}>
              {row[key] || '-'}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );

  return (
    <>
      <Modal show={modalToggle} onHide={handleSetModalToggle} size="lg" centered>
        <Modal.Body>
          <Tabs defaultActiveKey="schemaErrors">
            <Tab eventKey="schemaErrors" title="Schema Errors">
              <BatchShowJson json={selectedAccount.schemaErrors} />
            </Tab>
            <Tab eventKey="transformedRow" title="Transformed Row">
              <BatchShowJson json={selectedAccount.transformedRow} />
            </Tab>
            <Tab eventKey="transformedRowDetail" title="Transform Detail">
              <BatchShowJson json={selectedAccount.transformedRowDetail} />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
      <Row>
        <Col>
          {accounts.map(({ id, rowIndex, schemaErrors, transformedRow }) => (
            <Card key={`accountError-${rowIndex}`} className="accountError">
              <Card.Header>
                <div className="float-none">
                  <div className="float-left">
                    <h1 className="h4 mt-2">{`Errors for Row ${rowIndex}`}</h1>
                  </div>
                  <div className="float-right">
                    <Button
                      variant="link"
                      onClick={accountDetailClicked}
                      disabled={modalToggle}
                      data-id={id}
                    >
                      detail..
                    </Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                {_map(schemaErrors, (errors, key) => (
                  <Row as="dl" key={key}>
                    {dataRow(key, errors)}
                  </Row>
                ))}
                <Row className="mt-4" style={{ overflowX: 'scroll' }}>
                  <Col>{renderTransformedRow(transformedRow)}</Col>
                </Row>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default BatchShowAccountErrors;
