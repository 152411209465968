import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

const locationHelper = locationHelperBuilder({});

export const userIsAdmin = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => {
    const { auth } = state;
    return !auth.admin;
  },
  wrapperDisplayName: 'UserIsAdmin',
});

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) => {
    const { auth } = state;
    return auth.isAuthenticated && !auth.isAuthenticating;
  },
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/dashboard',
  allowRedirectBack: false,
  authenticatedSelector: (state) => {
    const { auth } = state;
    return !auth.isAuthenticated;
  },
  wrapperDisplayName: 'UserIsNotAuthenticated',
});
