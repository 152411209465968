import React from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { LinkContainer } from 'react-router-bootstrap';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import {
  Confirm,
  renderHocOverlay,
  renderHocError,
  renderHocPage,
} from '../components';

import batchTemplateDeleteMutation from '../mutations/batch_template_delete_mutation';
import pageBatchTemplateListQuery from '../queries/page_batch_template_list_query';

import {
  BatchTypes,
  IndustryTypes,
  Modes,
} from '../schemas/tables';

const BatchTemplateList = (props) => {
  const handleDeleteClick = (e) => {
    props.mutationSet(true);
    const batchTemplateId = e.currentTarget.getAttribute('data-id');
    props.batchTemplateDeleteMutation({
      variables: {
        id: batchTemplateId,
      },
      refetchQueries: () => [
        { query: pageBatchTemplateListQuery },
      ],
    })
      .then(() => {
        props.mutationSuccess('Batch template deleted');
      })
      .catch((res) => {
        props.mutationFailure(res);
      });
  };

  const renderActionsCell = (cell, row) => (
    <>
      <LinkContainer
        to={`/batch_templates/${row.id}/edit`}
      >
        <Button
          variant="link"
          size="small"
        >
          edit
        </Button>
      </LinkContainer>
      <Confirm
        confirmId={`batch-template-delete-${row.id}`}
        dataId={row.id}
        // disabled={checkStatus === 'Completed'} // todo no delete if has upload records?
        onConfirm={handleDeleteClick}
        title="Delete Batch Template"
        body="Are you sure you want to delete this batch template"
        confirmText="Confirm"
      >
        <Button
          variant="link"
          size="xsmall"
          // disabled={checkStatus === 'Completed'}
        >
          delete
        </Button>
      </Confirm>
    </>
  );

  const renderBatchTypeCell = (cell) => BatchTypes[cell];
  const renderIndustryTypeCell = (cell) => IndustryTypes[cell];
  const renderModeCell = (cell) => Modes[cell];

  const tableColumns = [
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'batchType',
      text: 'Batch Type',
      formatter: renderBatchTypeCell,
    },
    {
      dataField: 'industryType',
      text: 'Industry Type',
      formatter: renderIndustryTypeCell,
    },
    {
      dataField: 'mode',
      text: 'Mode',
      formatter: renderModeCell,
    },
    {
      dataField: 'notificationEmail',
      text: 'Notified',
    },
    {
      dataField: 'version',
      text: 'Version',
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      formatter: renderActionsCell,
    },
  ];

  const renderContent = () => (
    <Container fluid className="p-0">
      <Row>
        <Col sm={12}>
          <div className="float-none">
            <div className="float-left">
              <h1 className="h3 mb-3">Batch Templates</h1>
            </div>
            <div className="float-right">
              <LinkContainer to="/batch_templates/new">
                <Button
                  variant="primary"
                >
                  Add a Batch Template
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                data={props.pageQuery.batchTemplateList}
                columns={tableColumns}
                bootstrap4
                bordered={false}
                pagination={paginationFactory({
                  sizePerPage: 25,
                  sizePerPageList: [10, 25, 50],
                  hidePageListOnlyOnePage: true,
                })}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return (
    <div>
      { renderHocOverlay(props) }
      { renderHocError(props) }
      { renderHocPage(props) && renderContent() }
    </div>
  );
};

function mapStateToProps(state) {
  return {
    settingsMutating: state.settings.mutating,
  };
}

export default compose(
  graphql(batchTemplateDeleteMutation, {
    name: 'batchTemplateDeleteMutation',
  }),
  graphql(pageBatchTemplateListQuery, {
    name: 'pageQuery',
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
)(BatchTemplateList);
