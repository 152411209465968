import authReducer, { initialState as authInitialState } from './auth_reducer';
import settingsReducer, { initialState as settingsInitialState } from './settings_reducer';

export const reducers = {
  auth: authReducer,
  settings: settingsReducer,
};

export const initialState = {
  auth: authInitialState,
  settings: settingsInitialState,
};
