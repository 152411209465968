import React from 'react';
import Spinner from 'react-spinkit';

const SubmitButtonSpinner = () => (
  <Spinner
    fadeIn="quarter"
    name="circle"
    color="white"
    style={{
      display: 'inline-block',
      width: '0.75rem',
      height: '0.75rem',
      marginRight: '0.625rem',
    }}
  />
);

export default SubmitButtonSpinner;
