import { Users as UsersIcon } from 'react-feather';

import Logout from '../containers/logout';
import Login from '../containers/login';

export default {
  path: '/',
  name: 'Auth',
  icon: UsersIcon,
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      requireNotAuthenticated: true,
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
      sidebar: true,
    },
  ],
};
