import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment-timezone';

import _get from 'lodash/get';
import _includes from 'lodash/includes';
// import _padStart from 'lodash/padStart';

import { mutationSet, mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import { Confirm, renderHocOverlay, renderHocError, renderHocPage } from '../components';

// import { getExport } from '../lib/utils';

import batchDeleteMutation from '../mutations/batch_delete_mutation';
import batchProcessMutation from '../mutations/batch_process_mutation';
import batchPublishMutation from '../mutations/batch_publish_mutation';
import batchPollMutation from '../mutations/batch_poll_mutation';
import batchResetMutation from '../mutations/batch_reset_mutation';

import pageBatchListQuery from '../queries/page_batch_list_query';

import {
  BatchStatuses,
  MutableBatchStatuses,
  ProcessableBatchStatuses,
  PublishableBatchStatuses,
  BatchTypes,
  Modes,
} from '../schemas/tables';

moment.locale('en-nz');

const BatchList = (props) => {
  const handleDeleteClick = (e) => {
    props.mutationSet(true);
    const batchId = e.currentTarget.getAttribute('data-id');
    props
      .batchDeleteMutation({
        variables: {
          id: batchId,
        },
        refetchQueries: () => [{ query: pageBatchListQuery }],
      })
      .then(() => {
        props.mutationSuccess('Batch deleted');
      })
      .catch((res) => {
        props.mutationFailure(res);
      });
  };

  const handleProcessClick = (e) => {
    props.mutationSet(true);
    const batchId = e.currentTarget.getAttribute('data-id');
    props
      .batchProcessMutation({
        variables: {
          id: batchId,
        },
        refetchQueries: () => [{ query: pageBatchListQuery }],
      })
      .then(() => {
        props.mutationSuccess('Batch process');
      })
      .catch((res) => {
        props.mutationFailure(res);
      });
  };

  const handlePublishClick = (e) => {
    props.mutationSet(true);
    const batchId = e.currentTarget.getAttribute('data-id');
    props
      .batchPublishMutation({
        variables: {
          id: batchId,
        },
        refetchQueries: () => [{ query: pageBatchListQuery }],
      })
      .then(() => {
        props.mutationSuccess('Batch publish');
      })
      .catch((res) => {
        props.mutationFailure(res);
      });
  };

  const handlePollClick = (e) => {
    props.mutationSet(true);
    const batchId = e.currentTarget.getAttribute('data-id');
    props
      .batchPollMutation({
        variables: {
          id: batchId,
        },
        refetchQueries: () => [{ query: pageBatchListQuery }],
      })
      .then(() => {
        props.mutationSuccess('Batch poll');
      })
      .catch((res) => {
        props.mutationFailure(res);
      });
  };

  const handleResetClick = (e) => {
    props.mutationSet(true);
    const batchId = e.currentTarget.getAttribute('data-id');
    props
      .batchResetMutation({
        variables: {
          id: batchId,
        },
        refetchQueries: () => [{ query: pageBatchListQuery }],
      })
      .then(() => {
        props.mutationSuccess('Batch reset');
      })
      .catch((res) => {
        props.mutationFailure(res);
      });
  };

  // const handleGetExport = (e) => {
  //   props.mutationSet(true);
  //   let displayName;
  //   const batchId = e.target.getAttribute('data-id');
  //   const exportPath = e.target.getAttribute('data-export-path');
  //   if (/rcanz\.xml/.test(exportPath)) {
  //     const batch = props.pageQuery.batchList.find((b) => b.id === parseInt(batchId, 10));
  //     const { id, extractAt, version, batchType } = batch;
  //     displayName = [
  ////       TODO get signatoryId and subId from reporter
  //       moment(extractAt).tz('Pacific/Auckland').format('YYYY-MM-DD'),
  //       moment(extractAt).tz('Pacific/Auckland').format('kk-mm-ss'),
  //       _padStart(id, 10, 0),
  //       `v${version.replace(/\./, '-')}`,
  //       batchType,
  //     ].join('_');
  //   }
  //   getExport(exportPath, displayName)
  //     .then(() => {
  //       props.mutationSet(false);
  //     })
  //     .catch((err) => props.mutationFailure(err));
  // };

  const renderActionsCell = (cell, row) => (
    <>
      <LinkContainer to={`/batches/${row.id}`}>
        <Button variant="outline-primary" size="small" block>
          show
        </Button>
      </LinkContainer>
      <LinkContainer to={`/batches/${row.id}/edit`}>
        <Button
          variant="outline-primary"
          size="small"
          disabled={!_includes(MutableBatchStatuses, row.batchStatus)}
          block
        >
          edit
        </Button>
      </LinkContainer>
      <Confirm
        confirmId={`batch-delete-${row.id}`}
        dataId={row.id}
        onConfirm={handleDeleteClick}
        title="Delete Batch "
        body="Are you sure you want to delete this batch"
        confirmText="Confirm"
      >
        <Button
          variant="outline-primary"
          size="xsmall"
          disabled={!_includes(MutableBatchStatuses, row.batchStatus)}
          block
        >
          delete
        </Button>
      </Confirm>
      <Confirm
        confirmId={`batch-process-${row.id}`}
        dataId={row.id}
        onConfirm={handleProcessClick}
        title="Process Batch "
        body="Are you sure you want to process this batch."
        confirmText="Confirm"
      >
        <Button
          variant="outline-primary"
          size="xsmall"
          disabled={!_includes(ProcessableBatchStatuses, row.batchStatus)}
          block
        >
          process
        </Button>
      </Confirm>
      <Confirm
        confirmId={`batch-publish-${row.id}`}
        dataId={row.id}
        onConfirm={handlePublishClick}
        title="Publish Batch"
        body="Are you sure you want to publish this batch."
        confirmText="Confirm"
      >
        <Button
          variant="outline-primary"
          size="xsmall"
          disabled={!_includes(PublishableBatchStatuses, row.batchStatus)}
          block
        >
          publish
        </Button>
      </Confirm>
      {props.authUser.developer && (
        <>
          <LinkContainer to={`/batches/${row.id}/edit`}>
            <Button variant="outline-primary" size="small" block>
              UNSAFE_edit
            </Button>
          </LinkContainer>
          <Confirm
            confirmId={`batch-publish-${row.id}`}
            dataId={row.id}
            onConfirm={handlePublishClick}
            title="REPUBLISH Batch"
            body="Hope you know what you're doing.."
            confirmText="Confirm"
          >
            <Button variant="outline-primary" size="xsmall" block>
              UNSAFE_publish
            </Button>
          </Confirm>
          <Confirm
            confirmId={`batch-poll-${row.id}`}
            dataId={row.id}
            onConfirm={handlePollClick}
            title="REPOLL Batch"
            body="Hope you know what you're doing.."
            confirmText="Confirm"
          >
            <Button variant="outline-primary" size="xsmall" block>
              UNSAFE_poll
            </Button>
          </Confirm>
          <Confirm
            confirmId={`batch-poll-${row.id}`}
            dataId={row.id}
            onConfirm={handleResetClick}
            title="RESET Batch"
            body="Hope you know what you're doing.."
            confirmText="Confirm"
          >
            <Button variant="outline-primary" size="xsmall" block>
              UNSAFE_reset
            </Button>
          </Confirm>
        </>
      )}
      {/*
      <br />
      <Button
        variant="outline-primary"
        size="xsmall"
        data-id={row.id}
        data-export-path={`batches/${row.id}/rcanz.xml`}
        onClick={handleGetExport}
         block
      >
        download XML
      </Button>
      */}
      {/*
        <br />
        <Button
          variant="outline-primary"
          size="xsmall"
          data-export-name={`batches/${row.id}/schema_errors`}
          data-export-type="csv"
          onClick={handleGetExport}
           block
        >
          download errors
        </Button>
      */}
    </>
  );

  const renderExtractAtCell = (cell) => moment(cell).tz('Pacific/Auckland').format('LLL z');
  const renderBatchStatusCell = (cell) => _get(BatchStatuses, [cell, 'description']);
  const renderBatchTypeCell = (cell) => BatchTypes[cell];
  const renderModeCell = (cell) => Modes[cell];

  const tableColumns = [
    {
      dataField: 'extractAt',
      text: 'Extracted on',
      formatter: renderExtractAtCell,
      style: { verticalAlign: 'top' },
    },
    {
      dataField: 'batchStatus',
      text: 'Batch Status',
      formatter: renderBatchStatusCell,
      style: { verticalAlign: 'top' },
    },
    {
      dataField: 'batchType',
      text: 'Batch Type',
      formatter: renderBatchTypeCell,
      style: { verticalAlign: 'top' },
    },
    {
      dataField: 'mode',
      text: 'Mode',
      formatter: renderModeCell,
      style: { verticalAlign: 'top' },
    },
    {
      dataField: 'notificationEmail',
      text: 'Notified',
      style: { verticalAlign: 'top' },
    },
    {
      dataField: 'version',
      text: 'Version',
      style: { verticalAlign: 'top' },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      formatter: renderActionsCell,
    },
  ];

  const renderContent = () => (
    <Container fluid className="p-0">
      <Row>
        <Col sm={12}>
          <div className="float-none">
            <div className="float-left">
              <h1 className="h3 mb-3">Batches</h1>
            </div>
            <div className="float-right">
              <LinkContainer to="/batches/new">
                <Button variant="primary">Add a Batch</Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                data={props.pageQuery.batchList}
                columns={tableColumns}
                bootstrap4
                bordered={false}
                pagination={paginationFactory({
                  sizePerPage: 5,
                  sizePerPageList: [5, 10, 15],
                  hidePageListOnlyOnePage: true,
                })}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return (
    <div>
      {renderHocOverlay(props)}
      {renderHocError(props)}
      {renderHocPage(props) && renderContent()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    authUser: state.auth.user,
    settingsMutating: state.settings.mutating,
  };
}

export default compose(
  graphql(batchDeleteMutation, {
    name: 'batchDeleteMutation',
  }),
  graphql(batchProcessMutation, {
    name: 'batchProcessMutation',
  }),
  graphql(batchPublishMutation, {
    name: 'batchPublishMutation',
  }),
  graphql(batchPollMutation, {
    name: 'batchPollMutation',
  }),
  graphql(batchResetMutation, {
    name: 'batchResetMutation',
  }),
  graphql(pageBatchListQuery, {
    name: 'pageQuery',
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  })
)(BatchList);
