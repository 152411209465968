/* eslint-disable import/prefer-default-export */
import { ReporterNames } from './tables';

const reporterFormSchema = {
  type: 'object',
  required: ['reporterName', 'username', 'sftpUrl', 'signatoryId', 'signatorySubId'],
  properties: {
    reporterName: {
      type: 'string',
      enum: ReporterNames,
    },
    username: { type: 'string', minLength: 1, maxLength: 255 },
    password: { type: 'string', minLength: 6, maxLength: 255 },
    passwordConfirmation: {
      type: 'string',
      const: {
        $data: '1/password',
      },
      errorMessage: {
        const: 'should match password',
      },
    },
    sftpUploadPath: { type: 'string', minLength: 1, maxLength: 255 },
    sftpDownloadPath: { type: 'string', minLength: 1, maxLength: 255 },
    active: { type: 'boolean' },
    test: { type: 'boolean' },
    signatoryId: {
      type: 'string',
      minLength: 12,
      maxLength: 12,
    },
    signatorySubId: {
      type: 'string',
      minLength: 12,
      maxLength: 12,
    },
  },
  allOf: [
    {
      if: {
        required: ['id'],
      },
      else: {
        required: ['password', 'passwordConfirmation'],
      },
    },
    {
      if: {
        required: ['password'],
      },
      then: {
        required: ['passwordConfirmation'],
      },
    },
  ],
};

export { reporterFormSchema };
