export default [
  'id',
  'description',
  'providerReference',
  'batchType',
  'industryType',
  'mode',
  'version',
  'nameOfTheProvider',
  'notificationEmail',
  'mainContactEmail',
  'mainContactName',
  'mainContactPhone',
  'optionalContactEmail',
  'optionalContactName',
  'optionalContactPhone',
];
