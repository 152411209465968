import React from 'react';
import { Bar } from 'react-chartjs-2';

import {
  Card,
  Dropdown,
} from 'react-bootstrap';

import { MoreHorizontal } from 'react-feather';

const BarChart = () => {
  const theme = {
    primary: '#2c7be5',
    secondary: '#9d7bd8',
    tertiary: '#5997eb',
    success: '#4caf50',
    info: '#47bac1',
    warning: '#ff9800',
    danger: '#e51c23',
  };
  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Last year',
        backgroundColor: theme.warning,
        borderColor: theme.warning,
        hoverBackgroundColor: theme.warning,
        hoverBorderColor: theme.warning,
        data: [54, 67, 41, 55, 62, 45, 55, 73, 60, 76, 48, 79],
      },
      {
        label: 'This year',
        backgroundColor: theme.primary,
        borderColor: theme.primary,
        hoverBackgroundColor: theme.primary,
        hoverBorderColor: theme.primary,
        data: [69, 66, 24, 48, 52, 51, 44, 53, 62, 79, 51, 68],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: false,
          ticks: {
            stepSize: 20,
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.75,
          categoryPercentage: 0.5,
          stacked: false,
          gridLines: {
            color: 'transparent',
          },
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-right">
          <Dropdown alignRight id="dashboard-bar-chart-toggle">
            <Dropdown.Toggle tag="a">
              <MoreHorizontal className="text-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title tag="h5" className="mb-0">
          Import Numbers
        </Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <Bar data={data} options={options} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BarChart;
