import actionTypes from '../constants';

export const initialState = {
  isOpenSidebar: true,
  mutating: false,
};

export default function settingsReducer(state = initialState, action) {
  const { type, payload, error } = action;
  if (error) {
    return state;
  }
  switch (type) {
    case actionTypes.SIDEBAR_VISIBILITY_TOGGLE:
      return {
        ...state,
        isOpenSidebar: !state.isOpenSidebar,
      };
    case actionTypes.SETTINGS_SET:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
