import { Form, FormGroup, Button, Card, Col, Container, Row, ButtonGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { LinkContainer } from 'react-router-bootstrap';
import {
  reduxForm,
  Field,
  getFormValues,
  change as changeFieldValue,
  initialize as initializeForm,
  reset as resetForm,
} from 'redux-form';
import Spinner from 'react-spinkit';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import _omitBy from 'lodash/omitBy';
import _pick from 'lodash/pick';

import { mutationSet, mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import Loader from '../components/loader';
import CheckboxField from '../components/form/checkbox_field';
import InputField from '../components/form/input_field';

import { coerceInput } from '../lib/utils';

import reporterCreateMutation from '../mutations/reporter_create_mutation';
import reporterUpdateMutation from '../mutations/reporter_update_mutation';

import reporterQuery from '../queries/reporter_query';
import reporterListQuery from '../queries/reporter_list_query';

import { ReporterNames } from '../schemas/tables';

import makeFormValidator from '../lib/make_form_validator';

import { reporterFormSchema } from '../schemas';

import reporterWhiteList from '../constants/reporter_white_list';

let isInitialisedReporterForm = false;

class ReporterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.match.params.id,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    isInitialisedReporterForm = false;
  }

  canRenderLoader = () => !!this.props.settingsMutating;

  canRenderContent = () =>
    !!(isInitialisedReporterForm && (!this.state.updating || _get(this.props, 'formValues.id')));

  handleSubmit(data) {
    const submitData = _cloneDeep(data);
    delete submitData.passwordConfirmation;
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: coerceInput(submitData) },
      refetchQueries: () => [{ query: reporterListQuery }],
    };
    if (this.state.updating) {
      mutation = this.props.reporterUpdateMutation;
      mutationMessage = 'Credit Reporter update';
      mutationData.variables.id = this.props.match.params.id;
    } else {
      mutation = this.props.reporterCreateMutation;
      mutationMessage = 'Credit Reporter create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage, '/reporters');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  renderTitle = () => {
    if (this.state.updating) {
      return 'Edit Credit Reporter';
    } else {
      return 'New Crdit Reporter';
    }
  };

  renderContent() {
    const { handleSubmit, submitting } = this.props;

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{this.renderTitle()}</h1>
        <Card>
          <Card.Body>
            <Form noValidate onSubmit={handleSubmit(this.handleSubmit)}>
              <Form.Row>
                <Field
                  type="text"
                  name="reporterName"
                  component={InputField}
                  asElement="select"
                  selectOptions={_map(ReporterNames, (name) => ({ id: name, name }))}
                >
                  Credit Reporter Name
                </Field>
                <Field type="text" name="signatoryId" component={InputField}>
                  Signatory Id
                </Field>
                <Field type="text" name="signatorySubId" component={InputField}>
                  Signatory Sub Id
                </Field>
              </Form.Row>
              <Form.Row>
                <Field type="text" name="sftpUrl" component={InputField}>
                  Secure FTP URL
                </Field>
                <Field type="text" name="sftpUploadPath" component={InputField}>
                  Upload Path (e.g. Test/Input, Prod or leave empty)
                </Field>
                <Field type="text" name="sftpDownloadPath" component={InputField}>
                  Download Path (e.g. Test/Output, Prod or leave empty)
                </Field>
              </Form.Row>
              <Form.Row>
                <Field type="text" name="username" component={InputField}>
                  Secure FTP Username
                </Field>
                <Field type="text" name="password" component={InputField}>
                  Secure FTP Password
                </Field>
                <Field type="text" name="passwordConfirmation" component={InputField}>
                  Password Confirmation
                </Field>
              </Form.Row>
              <Form.Row>
                <Field name="active" component={CheckboxField}>
                  Active?
                </Field>
                <Field name="encrypted" component={CheckboxField}>
                  Encrypted?
                </Field>
                <Field name="test" component={CheckboxField}>
                  Test Account?
                </Field>
              </Form.Row>

              <FormGroup as={Row}>
                <Col sm={12}>
                  <ButtonGroup className="float-right">
                    <LinkContainer to="/reporters">
                      <Button type="reset" variant="danger" disabled={submitting}>
                        Cancel
                      </Button>
                    </LinkContainer>
                    <Button
                      className="float-right"
                      type="submit"
                      variant="primary"
                      disabled={submitting}
                    >
                      {submitting && (
                        <Spinner
                          fadeIn="quarter"
                          name="circle"
                          color="white"
                          style={{
                            display: 'inline-block',
                            width: '0.75rem',
                            height: '0.75rem',
                            marginRight: '0.625rem',
                          }}
                        />
                      )}
                      {this.state.updating ? 'Update' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </Col>
              </FormGroup>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  render() {
    return (
      <div>
        {this.canRenderLoader() && <Loader />}
        {this.canRenderContent() && this.renderContent()}
      </div>
    );
  }
}

function pickInitialValues(initialQuery, updating) {
  if (!isInitialisedReporterForm) {
    if (!updating) {
      isInitialisedReporterForm = true;
      return {
        active: false,
      };
    } else if (!initialQuery.loading) {
      isInitialisedReporterForm = true;
      const data = _pick(_omitBy(_cloneDeep(initialQuery.reporter), _isNil), reporterWhiteList);
      return data;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.reporterQuery, !!props.match.params.id);
  return {
    initialValues,
    formValues: getFormValues('ReporterForm')(state),
    settingsMutating: state.settings.mutating,
  };
}

export default compose(
  graphql(reporterCreateMutation, {
    name: 'reporterCreateMutation',
  }),
  graphql(reporterUpdateMutation, {
    name: 'reporterUpdateMutation',
  }),
  graphql(reporterQuery, {
    name: 'reporterQuery',
    skip: (props) => !props.match.params.id,
    options: (props) => ({ variables: { id: props.match.params.id }, fetchPolicy: 'network-only' }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    initializeForm,
    resetForm,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'ReporterForm',
    validate: makeFormValidator(reporterFormSchema),
  })
)(ReporterForm);
