/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  FormGroup,
} from 'react-bootstrap';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Form as FinalForm, Field } from 'react-final-form';

import _cloneDeep from 'lodash/cloneDeep';

import { mutationSet, mutationSuccess, mutationFailure } from '../actions/mutation_actions';
import userWhiteList from '../constants/user_white_list';
import { renderOverlay, renderError, renderPage } from '../components';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import InputField from '../components/form/rff_input_field';
import CheckboxField from '../components/form/rff_checkbox_field';
// import ConditionalField from '../components/form/rff_conditional_field';
import { coerceInput, pickValues } from '../lib/utils';
import userCreateMutation from '../mutations/user_create_mutation';
import userUpdateMutation from '../mutations/user_update_mutation';
import userQuery from '../queries/user_query';

import { userFormValidator } from '../validators';

const UserForm = () => {
  const history = useHistory();
  const params = useParams();
  // const { data: pageData, loading: pageLoading, error: pageError }
  //  = useQuery(pageUserFormQuery, {
  //   variables: { ...(params && { userId: params.id }) },
  //   // skip: () => !params.id,
  //   // fetchPolicy: 'network-only',
  //   // skip: (props) => {
  //   //   console.log('slip');
  //   //   return true;
  //   // },
  // });
  /// TODO Using useLazyQuery here because skip is not working on useQuery
  /// TODO Try using @skip(if: ....) decorator in the query itself
  const [getPage, { data: pageData, loading: pageLoading, error: pageError }] = useLazyQuery(
    userQuery
  );
  const [userCreate] = useMutation(userCreateMutation);
  const [userUpdate] = useMutation(userUpdateMutation);
  const user = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.id) {
      getPage({
        variables: { id: params.id },
      });
    }
  }, [params.id, getPage]);

  const isSelf = () => params.id && user.id === parseInt(params.id, 10);

  const onCancel = () => {
    history.push('/users');
  };

  const onFormSubmit = (data) => {
    const submitData = _cloneDeep(data);

    dispatch(mutationSet(true));
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: coerceInput(submitData) },
    };

    if (params.id) {
      mutation = userUpdate;
      mutationMessage = 'User update';
      mutationData.variables.id = params.id;
    } else {
      mutation = userCreate;
      mutationMessage = 'User create';
    }
    return mutation(mutationData)
      .then(() => {
        dispatch(mutationSuccess(mutationMessage, '/users'));
      })
      .catch((err) => dispatch(mutationFailure(err)));
  };

  const renderContent = () => (
    <Container fluid className="p-0">
      <Row>
        <Col sm={12}>
          <div className="float-none">
            <div className="float-left">
              <h1 className="h3 mb-3">{params.id ? 'Edit User' : 'New User'}</h1>
            </div>
            <div className="float-right">
              <LinkContainer exact to="/users">
                <Button className="mr-2" variant="primary">
                  All Users
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={pickValues(pageData ? pageData.user : { admin: false }, userWhiteList)}
            onSubmit={onFormSubmit}
            validate={userFormValidator}
          >
            {({ handleSubmit, pristine, form, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Field name="email" component={InputField} labelWidth={4} size="lg">
                          Email
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          type="checkbox"
                          name="admin"
                          component={CheckboxField}
                          size="lg"
                          labelWidth={4}
                          disabled={!user.admin || isSelf()}
                        >
                          Adminstrator
                        </Field>
                      </Col>
                    </Row>
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          {params.id ? 'Update Password' : 'Create password'}
                          <hr />
                        </Card.Title>
                        <Row>
                          <Col>
                            <Field
                              name="password"
                              type="password"
                              component={InputField}
                              labelWidth={4}
                              size="lg"
                            >
                              New Password
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              name="passwordConfirmation"
                              type="password"
                              component={InputField}
                              labelWidth={4}
                              size="lg"
                            >
                              Password Confirmation
                            </Field>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <hr />
                    <FormGroup as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="mr-2">
                            <Button variant="danger" onClick={onCancel} disabled={submitting}>
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </FormGroup>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </Container>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating)}
      {renderError(pageError)}
      {renderPage(pageLoading, pageError) && renderContent()}
    </div>
  );
};

export default UserForm;
