// import React from 'react'
// import ReactDOM from 'react-dom'

// const App = (props) => {
//   return <p>Hello</p>
// }

// const rootEl = document.getElementById('root')

// function render (Component) {
//   ReactDOM.render(
//     <App />,
//     rootEl
//   )
// }
// render(App)

import 'whatwg-fetch';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
// import { AppContainer } from 'react-hot-loader';
// import { hot } from 'react-hot-loader';

import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from 'apollo-utilities';

import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';

import { PersistGate } from 'redux-persist/es/integration/react';

import { ConnectedRouter } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import Rollbar from 'rollbar/dist/rollbar.umd';

import _get from 'lodash/get';

import { store, history, persistor } from './store';

import { getIdToken, clearIdToken } from './lib/local_storage';
import { processMutationSubscription } from './lib/process_mutation_subscription';
import mutationSubscription from './subscriptions/mutation_subscription';

import Loader from './components/loader';

import { authLoadSuccess, authLoadFailure } from './actions/auth_actions';

// import App from './containers/app';
import App from './app';
// import App from './routes';

// const App = () => <p>Hello</p>;

let appRollbar;

if (process.env.NODE_ENV === 'production') {
  const rollbarConfig = {
    accessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN, // eslint-disable-line no-undef
    captureUncaught: true,
    payload: {
      environment: process.env.NODE_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.GIT_REVISION, // eslint-disable-line no-undef
          guess_uncaught_frames: true,
        },
      },
    },
  };
  appRollbar = new Rollbar(rollbarConfig);
  window.Rollbar = appRollbar;
}

const subscriptionClient = new SubscriptionClient(`${process.env.EXPRESS_WS_URL}/subscriptions`, {
  reconnect: true,
  connectionParams: () => ({
    token: getIdToken() && `${getIdToken()}`,
  }),
  // connectionCallback: (error, ack) => {}
});

const subscriptionLink = new WebSocketLink(subscriptionClient);

const httpLinkOptions = {
  uri: `${process.env.EXPRESS_API_URL}/graphql`,
};

const httpLinks = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(httpLinkOptions),
  new BatchHttpLink(httpLinkOptions)
);

const httpLink = ApolloLink.split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  subscriptionLink,
  httpLinks
);

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${getIdToken()}`,
    },
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    const isUnauthorized =
      networkError.statusCode === 401 ||
      networkError.message === 'Network request failed with status 401 - "Unauthorized"';
    if (isUnauthorized) {
      window.location = '/logout';
    }
  } else if (graphQLErrors) {
    const isUnauthorized = graphQLErrors.some(
      (graphQLError) =>
        graphQLError.message === 'Request failed with status code 401' ||
        _get(graphQLError, 'data.statusCode', 0) === 401
    );
    if (isUnauthorized) {
      window.location = '/logout';
    }
  }

  if (networkError) {
    if (window.$NODE_ENV === 'development') {
      console.log('[Network error]', JSON.stringify(networkError, undefined, 2));
    } else {
      window.Rollbar.error(networkError);
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      if (window.$NODE_ENV === 'development') {
        console.log('[GraphQL error]', JSON.stringify(graphQLError, undefined, 2));
      } else {
        window.Rollbar.error(
          _get(graphQLError, 'extensions.code', '[GraphQL error]'),
          graphQLError
        );
      }
    });
  }
});

const link = errorLink.concat(middlewareLink).concat(httpLink);

const cache = new InMemoryCache({
  addTypename: true,
  dataIdFromObject: (o) => {
    const id = o.id ? o.id : o.Id;
    // eslint-disable-next-line no-underscore-dangle
    return `${o.__typename}-${id}`;
  },
  cacheRedirects: {
    Query: {
      batch: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'BatchType', id: args.id }),
      batchTemplate: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'BatchTemplateType', id: args.id }),
      reporter: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'ReporterType', id: args.id }),
    },
  },
});

const apolloClient = new ApolloClient({
  link,
  cache: cache.restore(window.__APOLLO_STATE__ || {}), // eslint-disable-line no-underscore-dangle
  // connectToDevTools: true,
  // shouldBatch: true,
  // local state
  // https://blog.apollographql.com/announcing-apollo-client-2-5-c12230cabbb7
  // resolvers: { ... },
  // typeDefs: { ... },
});

const subscribeAll = () => {
  apolloClient
    .subscribe({
      query: mutationSubscription,
      variables: {},
      fetchPolicy: 'no-cache',
    })
    .subscribe({
      next(mutation) {
        processMutationSubscription(mutation, apolloClient);
      },
      error(error) {
        console.log('mutationSubscriptionError: ', error);
      },
    });
};

subscriptionClient.onDisconnected(() => {
  subscriptionClient.unsubscribeAll();
});

subscriptionClient.onConnected(() => {
  subscriptionClient.unsubscribeAll();
  subscribeAll();
});

subscriptionClient.onReconnected(() => {
  subscriptionClient.unsubscribeAll();
  subscribeAll();
});

apolloClient.closeWSConnection = () => {
  if (subscriptionClient) {
    subscriptionClient.close(true); // doesnt try to reconnect
  }
};

apolloClient.openWSConnection = () => {
  if (subscriptionClient) {
    subscriptionClient.close(true);
    subscriptionClient.connect();
  }
};

const token = getIdToken();

const onBeforeLift = () => {
  try {
    const decoded = jwtDecode(token);
    store.dispatch(authLoadSuccess(token, decoded));
  } catch (err) {
    console.log(err);
    clearIdToken();
    store.dispatch(authLoadFailure());
  }
};

const rootEl = document.getElementById('root');

const renderApp = (Component) => (
  <Provider store={store}>
    <PersistGate loading={<Loader />} onBeforeLift={onBeforeLift} persistor={persistor}>
      <ApolloProvider client={apolloClient}>
        <ConnectedRouter history={history}>
          {/* <Route path="/" component={Component} /> */}
          <Component />
        </ConnectedRouter>
      </ApolloProvider>
    </PersistGate>
  </Provider>
);

render(renderApp(App), rootEl);

// if (module.hot) {
//   module.hot.accept('./containers/app', () => {
//     // eslint-disable-next-line global-require
//     const NextApp = require('./containers/app').default;
//     render(renderApp(NextApp), rootEl);
//   });
// }

// renderApp(App);

// eslint-disable-next-line import/prefer-default-export
export { apolloClient };
