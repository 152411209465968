/* eslint-disable import/prefer-default-export */
import makeFormValidator from './make_form_validator';

import loginFormSchema from './login_form_schema';
import userFormSchema from './user_form_schema';

const loginFormValidator = makeFormValidator(loginFormSchema);
const userFormValidator = makeFormValidator(userFormSchema);

export { loginFormValidator, userFormValidator };

// TODO replace ./schemas implementation with this one
