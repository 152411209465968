/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import classNames from 'classnames';

import {
  Collapse,
  Badge,
} from 'react-bootstrap';

class SidebarCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
    this.getSidebarItemClass = this.getSidebarItemClass.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  getSidebarItemClass(path) {
    if (
      this.props.routerLocationPathname.indexOf(path) !== -1
       || (this.props.routerLocationPathname === '/' && path === '/dashboard')
    ) {
      return 'active';
    }
    return null;
  }

  toggle() {
    this.setState(prevState => (
      { open: !prevState.open }
    ));
  }

  render() {
    const {
      name,
      badgeColor,
      badgeText,
      icon: Icon,
      children,
      to,
    } = this.props;
    return (
      <li className={classNames('sidebar-item', this.getSidebarItemClass(to))}>
        <span
          data-toggle="collapse"
          className={classNames('sidebar-link', (this.state.open ? '' : 'collapsed'))}
          onClick={this.toggle}
          aria-expanded={this.state.open}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {
            badgeColor && badgeText && (
              <Badge variant={badgeColor} size={18} className="sidebar-badge">
                {badgeText}
              </Badge>
            )
          }
        </span>
        <Collapse in={this.state.open}>
          <ul id="item" className="sidebar-dropdown list-unstyled">
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
}

export default SidebarCategory;
