
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BatchTemplateTypeBase"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BatchTemplateType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"providerReference"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"batchType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"industryType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"version"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"nameOfTheProvider"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"notificationEmail"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mainContactEmail"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mainContactName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mainContactPhone"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"optionalContactEmail"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"optionalContactName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"optionalContactPhone"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":303}};
    doc.loc.source = {"body":"fragment BatchTemplateTypeBase on BatchTemplateType {\n  id\n  description\n  providerReference\n  batchType\n  industryType\n  mode\n  version\n  nameOfTheProvider\n  notificationEmail\n  mainContactEmail\n  mainContactName\n  mainContactPhone\n  optionalContactEmail\n  optionalContactName\n  optionalContactPhone\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
